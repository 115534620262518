import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Alert, Row, Col } from 'reactstrap';
import { BookingModalForm } from "../../Api";
import DOMPurify from 'dompurify';

const BookingModal = ({ isOpen, toggle, code, email }) => {

  const [responseHtml, setResponseHtml] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    reservationNo: code,
    email: email,
  });

  useEffect(() => {
    setFormData({
      reservationNo: code,
      email: email,
    });
  }, [code, email]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    setError('')
    document.getElementById("responseHtml").innerHTML = "";
    e.preventDefault();
    
     
      // API call if form is valid
      BookingModalForm(formData)
        .then((response) => {
          // Handle success with SweetAlert
        //   Swal.fire({
        //     icon: 'success',
        //     title: response.data.message,
        //     text: 'Your message has been sent!',
        //   });

        if(response.data.message == "Reservation fail to fetch"){
            setError(response.data.message);
        }else{
            const data = response.data.data;
            let pickup = new Date(data.start_date_time);
            const getAmPm = (hours) => (hours >= 12 ? 'PM' : 'AM');
            const get12HourFormat = (hours) => (hours % 12 || 12);
      
            const start_date_time =
            pickup.getDate().toString().padStart(2, '0') + '-' +
            (pickup.getMonth() + 1).toString().padStart(2, '0') + '-' +
            pickup.getFullYear() + ' , ' +
            get12HourFormat(pickup.getHours()).toString().padStart(2, '0') + ':' +
            pickup.getMinutes().toString().padStart(2, '0') + ' ' +
            getAmPm(pickup.getHours());

            let dropoff = new Date(data.end_date_time);
            const end_date_time =
            dropoff.getDate().toString().padStart(2, '0') + '-' +
            (dropoff.getMonth() + 1).toString().padStart(2, '0') + '-' +
            dropoff.getFullYear() + ' , ' +
            get12HourFormat(dropoff.getHours()).toString().padStart(2, '0') + ':' +
            dropoff.getMinutes().toString().padStart(2, '0') + ' ' +
            getAmPm(dropoff.getHours());

            
            let html = `
            <h5>Reservation Detail</h5>
            <p>
            <b>Code:</b> ${data.code}<br />
            <b>Start Time: </b> ${start_date_time}<br />
            <b>End Time: </b> ${end_date_time}<br /> 
            <b>Fleet: </b> ${data.fleet.brand} ${data.fleet.model}<br />
            <b>Pickup Location: </b> ${data.pickup_location.name} <br />
            `;

            if (data.return_location != null) {
                html += `<b>Dropoff Location: </b> ${data.return_location.name}<br />`;
            }

            html += `
            <b>Total cost: </b> €${data.after_coupon_cost} <br />  
            <b>Status: </b> ${data.status} <br />          
            </p>
            `;
            // setResponseHtml(html);
            document.getElementById("responseHtml").innerHTML = html;
            
        }

          // Clear the form fields
        //   setFormData({
        //     reservationNo: "",
        //     email: ""
        //   });

        })
        .catch((error) => {


          if (error.response && error.response.status === 422) {
            // Validation error, update form errors state
        
            Swal.fire({
              icon: 'error',
              title: 'Validation Error',
              text: 'Please check the form fields for errors.',
            });

          } else {
            // Other types of errors
            Swal.fire({
              icon: 'error',
              title: 'Error submitting form',
              text: 'An error occurred while submitting the form.',
            });
           
          }
          console.error("Error submitting form:", error);
        }).finally(() => {

        });;
    
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Manage My Booking</ModalHeader>
      <ModalBody>
        <Row>
            <Col lg="6" md="6" xs="6">
                <FormGroup className="mb-2">
                <Label for="reservationNo" className="mr-2">Reservation No:</Label>
                <Input type="text" name="reservationNo" id="reservationNo" value={formData.reservationNo } onChange={handleInputChange} />
                </FormGroup>
            </Col>
            <Col lg="6" md="6" xs="6">
                <FormGroup className="mb-2">
                <Label for="email" className="mr-2">Email:</Label>
                <Input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} />
                </FormGroup>
            </Col>
            <Col lg="12" md="12" xs="12">
                <Button color="primary" id="manageMyBookingSubmit" onClick={handleSubmit} style={{ float: 'right'}}>Submit</Button>
            </Col>
        </Row>
        {result && <Alert color="success" className="mt-3">{result}</Alert>}
        {error && <Alert color="danger" className="mt-3">{error}</Alert>}
        {responseHtml}        <div id="responseHtml">

        </div>
      </ModalBody>
    </Modal>
  );
};

export default BookingModal;
