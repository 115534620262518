import Swal from 'sweetalert2';
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import bgImg from "../assets/img/img-backg-pages.png";
import bgImgContactus from "../assets/img/contactus.jpg";
import PageTitle from "../components/PageTitle";
import PremiumSection from "../components/Home/PremiumSection";
import { Button, Col, Container, Input, Label, Row, Form } from "reactstrap";
import headset from "../assets/img/headset.png";
import location from "../assets/img/location-dot.png";
import envelope from "../assets/img/envelope.png";
import Footer from "../components/Footer";
import { ClipLoader } from "react-spinners";
import { createContactForm } from "../Api";

const ContactUs = () => {
  const [siteSettings, setSiteSettings] = useState(null);

  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const validateForm = () => {
    const errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Name is required";
    }
    if (!formData.emailAddress.trim()) {
      errors.emailAddress = "Email Address is required";
    } else if (!isValidEmail(formData.emailAddress)) {
      errors.emailAddress = "Invalid email address";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Mobile NUmber is required";
    }
    
    if (!formData.message.trim()) {
      errors.message = "Message field is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Basic email validation, you can use a library for more thorough validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // API call if form is valid
      createContactForm(formData)
        .then((response) => {
          // Handle success with SweetAlert
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            text: 'Your message has been sent!',
          });

          // Clear the form fields
          setFormData({
            fullName: "",
            emailAddress: "",
            phone: "",
            message: "",
          });

        })
        .catch((error) => {

          if (error.response && error.response.status === 422) {
            // Validation error, update form errors state
            const validationErrors = error.response.data.errors;
            setFormErrors(validationErrors);
            Swal.fire({
              icon: 'error',
              title: 'Validation Error',
              text: 'Please check the form fields for errors.',
            });

          } else {
            // Other types of errors
            Swal.fire({
              icon: 'error',
              title: 'Error submitting form',
              text: 'An error occurred while submitting the form.',
            });
           
          }
          console.error("Error submitting form:", error);
        }).finally(() => {
          setLoading(false); // Set loading back to false when the request is complete
        });
    }
  };



  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div className="page-contact-us">
      <Header />
      <PageTitle heading="Contact us" bgImg={bgImgContactus} gap="180px 0" />
      <div className="contact-section text-center">
        <Container>
          <div className="contact-info">
            <h4 data-aos="fade-up">READY TO HELP YOU</h4>
            <h2 className="mb-4" data-aos="fade-up">
              Send Your Message
            </h2>
            <p>
              {siteSettings?.['contactus_para']}
            </p>
            <Row>
              <Col lg="4" md="12" xs="12" className="col-contact-1">
                <div className="contact-info-detail">
                  <div className="img-div">
                    <img src={location} />
                  </div>
                  <h5>Our Location</h5>
                  <p><a href={`https://www.google.com/maps/place/${encodeURIComponent(siteSettings?.['site_location'])}`} target="_blank" style={{ 'color': 'inherit', 'textDecoration': 'none'}} rel="noopener noreferrer">
                    {siteSettings?.['site_location']}
                    </a>
                  </p>
                  <a href={`https://www.google.com/maps/place/${encodeURIComponent(siteSettings?.['site_location'])}`} target="_blank" rel="noopener noreferrer">
                    <Button color="primary">Visit Us</Button>
                  </a>
                </div>
              </Col>
              <Col lg="4" md="12" xs="12" className="col-contact-2">
                <div className="contact-info-detail">
                  <div className="img-div">
                    <img src={headset} />
                  </div>
                  <h5>Call Anytime</h5>
                  <ul>
                    <li>
                      <a href={`tel:${siteSettings?.['site_phone']}`}  style={{ 'color': 'inherit', 'textDecoration': 'none'}}>{siteSettings?.['site_phone']}</a>
                    </li>                    
                  </ul>
                  <a href={`tel:${siteSettings?.['site_phone']}`}>
                  <Button color="primary">Enquiry</Button>
                  </a>
                </div>
              </Col>
              <Col lg="4" md="12" xs="12" className="col-contact-3">
                <div className="contact-info-detail">
                  <div className="img-div">
                    <img src={envelope} />
                  </div>
                  <h5>Send Email</h5>
                  <ul>
                    <li>
                      <a href={`mailto:${siteSettings?.['site_email']}`}  style={{ 'color': 'inherit', 'textDecoration': 'none'}}>{siteSettings?.['site_email']}</a>
                        
                    </li>
                    
                  </ul>
                  <a href={`mailto:${siteSettings?.['site_email']}`}  style={{ 'color': 'inherit', 'textDecoration': 'none'}}>
                  <Button color="primary">Email Us</Button>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="contact-choice-section">
        <Container>
          <div className="contact-info text-center">
            <h4 data-aos="fade-up">LET'S CONNECT!</h4>
            <h2 className="mb-4" data-aos="fade-up">
              
              <span>LEAVE US A </span>
              <br /> MESSAGE
            </h2>

          </div>
          <div className="extra-border">
            <div className="form-contact-section">
              <p className="text-center">
                {siteSettings?.['contactus_leavemsg_para']}
                {/* <a href="#">Sign up Today!</a> */}
              </p>
              <hr />
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <Label>
                        Name<span className="text-danger">*</span>
                      </Label>
                      <Input placeholder="Enter Your Name" name="fullName"  value={formData.fullName}  onChange={handleInputChange} />
                    </div>
                    {formErrors.fullName && (
                      <div className="text-danger">{formErrors.fullName}</div>
                    )}
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <Label>
                        Mobile Number<span className="text-danger">*</span>
                      </Label>
                      <Input placeholder="Enter Your Number" name="phone"  value={formData.phone}  onChange={handleInputChange}/>
                    </div>
                    {formErrors.phone && (
                              <div className="text-danger">{formErrors.phone}</div>
                            )}
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <Label>
                        Email Address<span className="text-danger">*</span>
                      </Label>
                      <Input placeholder="Enter Your Email" name="emailAddress"  value={formData.emailAddress}  onChange={handleInputChange} />
                    </div>
                    {formErrors.emailAddress && (
                              <div className="text-danger">{formErrors.emailAddress}</div>
                            )}
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <div className="form-group">
                      <Label>Message</Label>
                      <Input placeholder="Enter Your Message"  name="message" value={formData.message} onChange={handleInputChange}/>
                    </div>
                    {formErrors.message && (
                          <div className="text-danger">{formErrors.message}</div>
                        )}
                  </Col>
                  <Col lg="12" className="text-center submit-btn">
                    <Button   type="submit" className="btn-contact-info" color="primary">
                      Submit Form
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Container>
      </div>

      <PremiumSection />
      <Footer />
      {loading && (
          <div className="loading-spinner">
             <ClipLoader color="#36D7B7" loading={loading} size={50} />
            Loading...
          </div>
        )}
    </div>
  );
};

export default ContactUs;
