import Swal from 'sweetalert2';
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import FormGroupSelect from "../GeneralComponents/FormGroupSelect";
import FormGroupInput from "../GeneralComponents/FormGroupInput";
import DatePicker from "react-datepicker";
import { getLocations } from "../../Api"; // Import the API function
import { addDays } from 'date-fns';


const PickUpForm = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateof, setSelectedDateof] = useState(null);
  const { btnText, boxShadow } = props;
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate(); // Add the useNavigate hook

  const formPanel = {
    pickUpLocation: 0,
    dropOffLocation: 0,
    pickUpDate: "",
    dropOffDate: "",
    // carType: 0,
  };
  const [fields, setFields] = useState(formPanel);
  const handleChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  
  

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
      const inputRef = useRef(null);
    
      useEffect(() => {
        if (inputRef.current) {
          inputRef.current.blur(); // Blur the input on mount
        }
      }, []);
    
      return (
        <input
          type="text"
          value={value}
          onClick={onClick}
          placeholder="d-m-Y"
          ref={inputRef}
          readOnly
        />
      );
    });

    // const handlePickupDateChange = (date) => {
    //   setSelectedDate(date);
    //   setSelectedDateof(null);      
    // };

    const handlePickupDateChange = (date) => {
      // Get the current time
      const currentTime = new Date();
      // Set the minimum selectable time to current time + 1 hour
      const minSelectableTime = new Date(currentTime.getTime() + (0.5 * 60 * 60 * 1000));
    
      // Check if the selected date is before the minimum selectable time
      if (date < minSelectableTime) {
        // If yes, set the selected date to null
        setSelectedDate(null);
      } else {
        // If not, set the selected date
        setSelectedDate(date);
      }
    
      // Reset the drop-off date to null
      setSelectedDateof(null);
    };


    const handleDropoffDateChange = (date) => {
        if (selectedDate != null && date <= selectedDate) {
            setSelectedDateof(null);
        } else {
            setSelectedDateof(date);
        }
    };

  const handleFilterButtonClick = () => {
    if (!fields.pickUpLocation || !fields.dropOffLocation || !selectedDate || !selectedDateof) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill in all required fields: Pickup Location, Dropoff Location, Pickup Date, and Dropoff Date.',
      });
      return false;
    }

    // const encodedPickupDate = encodeURIComponent(selectedDate);
    // const encodedDropoffDate = encodeURIComponent(selectedDateof);
    // const url = `/fleetView?pickUpLocation=${fields.pickUpLocation}&pickupDate=${encodedPickupDate}&dropoffDate=${encodedDropoffDate}`;


    // const params = new URLSearchParams();
    // params.append('pickUpLocation', fields.pickUpLocation);
    // params.append('pickupDate', selectedDate);
    // params.append('dropoffDate', selectedDateof);
    const url = `/fleetView`; //?${params.toString()}

    const paramFleet = {
      "pickUpLocation" : fields.pickUpLocation,
      "dropOffLocation" : fields.dropOffLocation,
      "pickupDate" : selectedDate,
      "dropoffDate" : selectedDateof
    };

    localStorage.setItem('paramsFleetPickup', JSON.stringify(paramFleet))
    navigate(url);
    const scrollToPosition = window.innerWidth <= 480 ? 1600 : 700;
    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
    
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // Construct the URL with form data as parameters
    // const url = `/fleetView?pickUpLocation=${fields.pickUpLocation}&pickupDate=${selectedDate}&dropoffDate=${selectedDateof}`;

    // Navigate to FleetView with the pre-filled form data


  };

  useEffect(() => {
    // Fetch the content_faq data from the API
    getLocations()
      .then((response) => {
        setLocations(response.data.data);

        
      })
      .catch((error) => {
        console.error("Error fetching content_faq data:", error);
      });
  }, []);

  return (
    <div className="section-form">
      <Container>
        <div className="form-section" style={{ boxShadow: boxShadow }}>
          <Row style={{ alignItems: "flex-end" }}>
            <Col lg="2" md="2" xs="12">
              <FormGroupSelect
                label="Pick-up Location"
                onChange={handleChange}
                name="pickUpLocation"
                value={fields?.pickUpLocation}
                defaultName="Select Location"
                list={locations}
                fieldId="id"
                fieldName="name"
              />
            </Col>
            <Col lg="2" md="2" xs="12">
              <FormGroupSelect
                label="Drop-off Location"
                onChange={handleChange}
                
                name="dropOffLocation"
                value={fields?.dropOffLocation}
                defaultName="Select Location"
                list={locations}
                fieldId="id"
                fieldName="name"
              />
            </Col>
            <Col lg="3" md="3" xs="12">
              <div className="form-group">
                <label className="form-label">Pick-up Date</label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handlePickupDateChange}
                  customInput={<CustomInput />}
                  showYearDropdown
                  scrollableYearDropdown
                  showTimeSelect
                  timeFormat="h:mm:aa"                   
                  dateFormat="dd-MM-yyyy h:mm:aa"
                  placeholder="dd-MM-yyyy HH:mm:ss"
                  minDate={new Date()}
             
                  disabledKeyboardNavigation
                  utcOffset={0}
                />
              </div>
            </Col>
            <Col lg="3" md="3" xs="12">
              <div className="form-group">
                <label className="form-label">Drop-off Date</label>
                <DatePicker
                  selected={selectedDateof}
                  // onChange={(date) => setSelectedDateof(date)}
                  onChange={handleDropoffDateChange}
                  customInput={<CustomInput />}
                  showYearDropdown
                  scrollableYearDropdown
                  showTimeSelect
                  timeFormat="h:mm:aa"
                  dateFormat="dd-MM-yyyy h:mm:aa"
                  placeholder="dd-MM-yyyy"
                  
                  minDate={selectedDate ? addDays(selectedDate, 1) : new Date()} // Set minDate to next day of pick-up date

                  // minDate={selectedDate || new Date()}  // minDate is set to pick-up date\\\\
                  
                  disabledKeyboardNavigation
                  utcOffset={0}
                />
              </div>
            </Col>
       
            <Col lg="2" md="2" xs="12">
              <div className="findcarbtn">
                <Button className="btn-formPanel" color="primary" onClick={handleFilterButtonClick}>
                  {btnText}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default PickUpForm;
