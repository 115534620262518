import Swal from 'sweetalert2';
import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";

import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import { createLongTermForm, createShortContactForm } from "../Api"; // Import the API function
import Header from "../components/Header";
import Footer from "../components/Footer";
import PremiumSection from "../components/Home/PremiumSection";
import PageTitle from "../components/PageTitle";
import AboutSection from "../components/About/AboutSection";
import bussiness from "../assets/img/business.png";
import emailIcon from "../assets/img/email-icon.png";
import phoneIcon from "../assets/img/phone-icon.png";
import bgImg from "../assets/img/long-term.png";
import circlesupport from "../assets/img/Feature Icon with circle.png";
import DOMPurify from 'dompurify';

const LongTermForm = () => {
  const iframe =
  
    '<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3232.859775146267!2d14.463848075796479!3d35.87695307252462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDUyJzM3LjAiTiAxNMKwMjcnNTkuMSJF!5e0!3m2!1sen!2snl!4v1706522769565!5m2!1sen!2snl" width="100%" height="400" style="border:0; border-radius: 10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    // '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d6884.647678747011!2d14.462037859355188!3d35.89546231748722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1703575125043!5m2!1sen!2s" width="100%" height="400" style="border:0; border-radius: 10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  
    function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }
  
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    companyName: "",
    services: "",
    message: "",
  });
  const [contactMsg, setContactMsg] = useState(null);

  
  const handleMessageChange = (e) => {
    setContactMsg(e.target.value);
  };
  

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])
  const sanitizedMainhead = DOMPurify.sanitize(siteSettings?.['long_mainhead']);


  
  const handleContactMessageSubmit = () => {
    setLoading(true);
    createShortContactForm(contactMsg)
    .then((response) => {
      Swal.fire({
        icon: 'success',
        title: "Successfully Submitted",
        text: 'Your message has been sent!',
      });
      setContactMsg('');
      setLoading(false);
    })
   
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }
    if (!formData.emailAddress.trim()) {
      errors.emailAddress = "Email Address is required";
    } else if (!isValidEmail(formData.emailAddress)) {
      errors.emailAddress = "Invalid email address";
    }
    if (!formData.companyName.trim()) {
      errors.companyName = "Company Name is required";
    }
    if (!formData.services.trim()) {
      errors.services = "Services field is required";
    }
    if (!formData.message.trim()) {
      errors.message = "Message field is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Basic email validation, you can use a library for more thorough validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // API call if form is valid
      createLongTermForm(formData)
        .then((response) => {
          // Handle success with SweetAlert
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            text: 'Your message has been sent!',
          });

          // Clear the form fields
          setFormData({
            fullName: "",
            emailAddress: "",
            companyName: "",
            services: "",
            message: "",
          });

        })
        .catch((error) => {

          if (error.response && error.response.status === 422) {
            // Validation error, update form errors state
            const validationErrors = error.response.data.errors;
            setFormErrors(validationErrors);
            Swal.fire({
              icon: 'error',
              title: 'Validation Error',
              text: 'Please check the form fields for errors.',
            });

          } else {
            // Other types of errors
            Swal.fire({
              icon: 'error',
              title: 'Error submitting form',
              text: 'An error occurred while submitting the form.',
            });
           
          }
          console.error("Error submitting form:", error);
        }).finally(() => {
          setLoading(false); // Set loading back to false when the request is complete
        });;
    }
  };


  return (
      <div className="page-longterm-form">
        <Header />
        <PageTitle
          subHeading="BOOK THE BEST CAR AT THE LOWEST PRICE"
          heading="Long-Term Form"
          bgImg={bgImg}
          gap="180px 0"
        />
        <Container className="custom-form"  style={{ marginBottom: '40px' }} >
          <div className="term-form-border">
            <Row className="contact-section">
              <Col lg="4" md="4">
                <div className="right-section">
                  <div className="image-icon">
                    <img src={circlesupport} alt="" />
                    <h3>LONG TERM LEASE </h3>
                    <p>{siteSettings?.['long_leasepara']}</p>
                  </div>
                  <Row className="support-sec mt-5">
                    <Col md="2" lg="2">
                      <div className="support-image">
                        <img src={emailIcon} alt="" />
                      </div>
                    </Col>
                    <Col md="8" lg="8">
                      <div className="support-text">
                        <p className="color-changetxt"> Send us a message </p>
                        <a href={`mailto:${siteSettings?.['site_email']}`} className='text-decoration-none'>
                            <p className="simple-txt">{siteSettings?.['site_email']}</p>
                          </a>
                      </div>
                    </Col>
                  </Row>
                  <Row className="support-sec mt-3">
                    <Col md="2" lg="2">
                      <div className="support-image">
                        <img src={phoneIcon} alt="" />
                      </div>
                    </Col>
                    <Col md="8" lg="8">
                      <div className="support-text">
                        <p className="color-changetxt"> Setup a call </p>
                        <a href={`tel:${siteSettings?.['site_phone']}`} className='text-decoration-none'>
                          <p className="simple-txt">
                            {siteSettings?.['site_phone']}
                          </p>
                        </a>

                        
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg="8" md="8">
                <div className="contact-choice-section ">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <div className="form-group">
                          <Label>Full Name</Label>
                          <Input placeholder="Enter Your Name"  name="fullName"  value={formData.fullName}  onChange={handleInputChange} />
                        </div>
                        {formErrors.fullName && (
                            <div className="text-danger">{formErrors.fullName}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12">
                        <div className="form-group">
                          <Label>Email Address</Label>
                          <Input placeholder="Enter Your Email"  name="emailAddress"  value={formData.emailAddress}  onChange={handleInputChange}/>
                        </div>
                        {formErrors.emailAddress && (
                            <div className="text-danger">{formErrors.emailAddress}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12" className="mt-3">
                        <div className="form-group">
                          <Label>Company Name</Label>
                          <Input placeholder="Enter Your Company" name="companyName"  value={formData.companyName}  onChange={handleInputChange} />
                        </div>
                        {formErrors.companyName && (
                            <div className="text-danger">{formErrors.companyName}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12" className="mt-3">
                        <div className="form-group">
                          <Label>Services</Label>
                          <Input placeholder="Enter required services"   name="services" value={formData.services}  onChange={handleInputChange}/>
                        </div>
                        {formErrors.services && (
                            <div className="text-danger">{formErrors.services}</div>
                          )}
                      </Col>
                      <Col lg="12" className="mt-3">
                        <FormGroup>
                          <Label>Enter Your Message</Label>
                          <Input
                            type="textarea"
                            rows={4}
                            cols={40}
                            placeholder="Enter your Message"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                          />                          
                        </FormGroup>
                        {formErrors.message && (
                            <div className="text-danger">{formErrors.message}</div>
                          )}
                      </Col>
                      <Col lg="12" className="mt-3">
                        <Button
                          type="submit"
                          className="btn-contact-info"
                          color="primary"
                        >
                          Send Message To Us
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        {/* <AboutSection /> */}
        <div className="section-bussiness">
          <Container>
            <Row style={{ alignItems: "center" }}>
              <Col md="12" lg="6" xs="12">
                <div className="business-img ">
                  <div
                    className="business-mn-img text-right"
                    style={{ textAlign: "right" }}
                  >
                    <img src={bussiness} />
                  </div>
                </div>
              </Col>
              <Col md="12" lg="6" xs="12">
                <div className="content-content mt-4">
                  <h2 data-aos="fade-up"
                   dangerouslySetInnerHTML={{ __html: sanitizedMainhead }}
                  
                  >
                    {/* <span>business </span> <br /> solutions */}
                  </h2>
                  <p>
                  {siteSettings?.['long_mainpara']}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-contact-long">
          <Container>
            <Row>
            {/*  */}
              <Col lg="5" md="5" xs="12" className="offset-md-7">
                <div className="inner-contact-long">
                  <h2 data-aos="fade-up">Contact us</h2>
                  <p>
                    {siteSettings?.['contactus_longterm_para']}
                  </p>
                  <ul>
                    <li>
                      <img src={emailIcon} />
                    </li>
                    <li>
                      <div className="msg-contct">
                        <p className="mb-0">Send us a message</p>
                        <a href={`mailto:${siteSettings?.['site_email']}`} className='text-decoration-none'>
                          <h4 className="mb-0" style={{ wordWrap: "break-word", maxWidth: "100%"}}>{siteSettings?.['site_email']}</h4>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <img src={phoneIcon} />
                    </li>
                    <li>
                      <div className="msg-contct">
                        <p className="mb-0">Setup a call</p>
                        <a href={`tel:${siteSettings?.['site_phone']}`} className='text-decoration-none'>
                          <h4 className="mb-0">{siteSettings?.['site_phone']}</h4>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="footer-contact">
                  
                    <Input placeholder="Write a message"  className="footer-field" name="message"  value={contactMsg}  onChange={handleMessageChange} />

                    <Button color="primary" className="footer-submit" onClick={handleContactMessageSubmit}>
                      SEND MESSAGE
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-visit">
          <Container>
            <div className="inner-visit text-center">
              <h2 data-aos="fade-up">
                <span>Visit</span> us Now
              </h2>
              <Iframe iframe={iframe} />
            </div>
          </Container>
        </div>
        <PremiumSection bg="#f4efef" />
        <Footer />
        {loading && (
          <div className="loading-spinner">
             <ClipLoader color="#36D7B7" loading={loading} size={50} />
            Loading...
          </div>
        )}
      </div>
  );
};


export default LongTermForm;
