import Swal from 'sweetalert2';
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { ClipLoader } from "react-spinners";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  UncontrolledAccordion,
} from "reactstrap";
import PageTitle from "../components/PageTitle";
import bgImg from "../assets/img/hero_01.png";
import usericon from "../assets/img/user-icon.png";
import bagicon from "../assets/img/bag-icon.png";
import dooricon from "../assets/img/door-icon.png";
import manulicon from "../assets/img/manul-icon.png";
import petrolicon from "../assets/img/petrol-icon.png";
import question from "../assets/img/question.png";
import product_one from "../assets/img/product-1.png";
import product_two from "../assets/img/product-2.png";
import product_three from "../assets/img/product-3.png";
import product_four from "../assets/img/product-4.png";
import product_five from "../assets/img/product-5.png";
import product_six from "../assets/img/product-6.png";
import circlesupport from "../assets/img/Feature Icon with circle.png";
import emailicon from "../assets/img/email 1.png";
import phoneicon from "../assets/img/viber 1.png";
import DatePicker from "react-datepicker";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import PickUpForm from "../components/Home/PickUpForm";
import Footer from "../components/Footer";
import FullView from "../components/Fleet/FullView";
import Faq from "../components/Fleet/Faq";
import FormGroupSelect from "../components/GeneralComponents/FormGroupSelect";
import FormGroupInput from "../components/GeneralComponents/FormGroupInput";
import { getFleets, checkBeforeCheckout, getFleetsPickupFilter, getFleetsLeftFilter, getLocations, createLongTermForm } from "../Api"; // Import the API function
import { addDays } from 'date-fns';

const FleetView = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [siteSettings, setSiteSettings] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
 

  const handleCheckoutChoose = (product) => {

    // if(!checkRequiredFields()) return;
    if (
      !fields.pickUpLocation ||
      !selectedDate ||
      !selectedDateof 
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill in all required fields: Pickup Location, Pickup Date, and Dropoff Date.',
      });
      return;
    }

    const checkoutData = {
      product: product,
      pickupLocation: fields.pickUpLocation,
      dropOffLocation: fields.dropOffLocation,
      pickupDate: selectedDate,
      dropoffDate: selectedDateof,
    };


    checkBeforeCheckout(checkoutData)
    .then((response) => {

      // return;
      let data = response.data.data;
      if(data['exists']) {
        Swal.fire({
          icon: 'error',
          title: 'Fleet not available',
          text: 'Please change Pickup, Dropoff Date or Fleet for reservation',
        });
        return;
      }else{
      }
      
      navigate("/checkout", { state: { 
        product: product,
        // pickupLocation: fields.pickUpLocation,
        // pickupDate: selectedDate,
        // dropoffDate: selectedDateof, 
        checkoutData: checkoutData,
      } });
      window.scrollTo({ top: 0, behavior: 'smooth' });


    })
    .catch((error) => {
      console.error("Error fetching content_faq data:", error);
    });

    
  };

  const handlePickupDateChange = (date) => {
    // Get the current time
    const currentTime = new Date();
    // Set the minimum selectable time to current time + 1 hour
    const minSelectableTime = new Date(currentTime.getTime() + (0.5 * 60 * 60 * 1000));
  
    // Check if the selected date is before the minimum selectable time
    if (date < minSelectableTime) {
      // If yes, set the selected date to null
      setSelectedDate(null);
    } else {
      // If not, set the selected date
      setSelectedDate(date);
    }
  
    // Reset the drop-off date to null
    setSelectedDateof(null);
  };


  const handleDropoffDateChange = (date) => {
      if (selectedDate != null && date <= selectedDate) {
          setSelectedDateof(null);
      } else {
          setSelectedDateof(date);
      }
  };

  const checkRequiredFields = () => {
    if (
      !fields.pickUpLocation ||
      !fields.dropOffLocation ||
      !selectedDate ||
      !selectedDateof 
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill in all required fields: Pickup Location, Dropoff Location, Pickup Date, and Dropoff Date.',
      });
      return false;
    }else {
      return true;
    }
  };
 


  const formPanel = {
    pickUpLocation: 0,
    dropOffLocation: 0,
    pickUpDate: "",
    dropOffDate: "",
    // carType: 0,
  };
  const [fields, setFields] = useState(formPanel);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])
  useEffect(() => {
    // Parse URL parameters and update form data
    const params = new URLSearchParams(location.search);
  
    if (params.has("pickupDate")) {

      setFields((prevFields) => ({
        ...prevFields,
        pickUpLocation: params.get("pickUpLocation") || 0,
        dropOffLocation: params.get("dropOffLocation") || 0,        
        pickUpDate: params.get("pickupDate") || "",
        dropOffDate: params.get("dropoffDate") || "",
      }));
      setSelectedDate((prevDate) => new Date(params.get("pickupDate")) || prevDate);
      setSelectedDateof((prevDate) => new Date(params.get("dropoffDate")) || prevDate);
  
      setTimeout(() => {
        document.getElementById("handlePickupBtn").click();
        // $("#handlePickupBtn").click();
        // handlePickupFilter();
        // Log the state values after handlePickupFilter is executed
      }, 50);
    } else if (params.has("type")) {
      // let typeName = params.get("type");
      // handleCheckboxChange("carType", typeName);
  
      setTimeout(() => {
        // document.getElementById("filterButton").click();
        // Log the state values after handleCheckboxChange is executed
      }, 1000);
    }
  }, [location.search]);


  useEffect(() => {
    const storedParamsFleetPickup = JSON.parse(localStorage.getItem('paramsFleetPickup'));
    const storedParamsType = JSON.parse(localStorage.getItem('paramsType'));

    if (storedParamsFleetPickup) {
      setFields((prevFields) => ({
        ...prevFields,
        pickUpLocation: storedParamsFleetPickup.pickUpLocation || 0,
        dropOffLocation: storedParamsFleetPickup.dropOffLocation || 0,
        pickUpDate: storedParamsFleetPickup.pickupDate || "",
        dropOffDate: storedParamsFleetPickup.dropoffDate || "",
      }));
      setSelectedDate((prevDate) => new Date(storedParamsFleetPickup.pickupDate) || prevDate);
      setSelectedDateof((prevDate) => new Date(storedParamsFleetPickup.dropoffDate) || prevDate);
  
      setTimeout(() => {
        document.getElementById("handlePickupBtn").click();
      }, 50);

      localStorage.removeItem('paramsFleetPickup');
    }

    if(storedParamsType){
     
      handleCheckboxChange("carType", storedParamsType.type);
  
      setTimeout(() => {
        document.getElementById("filterButton").click();
      }, 150);
      localStorage.removeItem('paramsType');
    }
  }, []);

  
  const handlePickupChange = (e) => {
    
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };
  const [carType, setCarType] = useState([
    { id: 'Small', name: 'Small' },
    { id: 'Medium', name: 'Medium' },
    { id: 'SUV', name: 'SUV' },
  ]);  

  const handleChangeCarType = (e) => {
    const selectedCarType = e.target.selectedValue;
    setFields({
      ...fields,
      carType: selectedCarType,
    });
  };
  
  
  const [seats, setSeats] = useState([]);
  const [transmission, setTransmission] = useState([]);
  const [bags, setBags] = useState([]);
  const [engine, setEngine] = useState([]);
  const [doors, setDoors] = useState([]);
  const [carProducts, setCarProducts] = useState([]);
  const [longformData, setLongFormData] = useState({
    fullName: "",
    emailAddress: "",
    companyName: "",
    services: "",
    message: "",
  });



  useEffect(() => {
    // Fetch the content_faq data from the API
    getFleets()
      .then((response) => {
        const fleetData = response.data.data;

        // Map the fetched fleet data to your desired format
        const updatedCarProducts = fleetData.map((fleet) => ({
          id: fleet.id,
          name: `Product ${fleet.id}`,
          description: fleet.description || `Description of Product ${fleet.id}`,
          imageUrl: fleet.thumbnail_image || '', // Use your image property from the fleet data
          carImageUrl: fleet.main_image || '',
          brand: fleet.brand || '',
          model: fleet.model || '',
          type: fleet.type || '',
          daily_rate: fleet.daily_rate || 0,
          weekly_rate: fleet.weekly_rate || 0,
          monthly_rate: fleet.monthly_rate || 0,
          seats: fleet.passengers || 0,
          bags: fleet.bags || 0,
          doors: fleet.doors || 0,
          transmission: fleet.transmission || '',
          fuel_type: fleet.fuel_type || '',
        }));

        // Update the state with the mapped fleet data
        setCarProducts(updatedCarProducts);
     
      })
      .catch((error) => {
        console.error("Error fetching content_faq data:", error);
      });
  }, []); 


  const handlePickupFilter = () => {
    // You may need to adjust the structure of your form data based on your API requirements
    // if(!checkRequiredFields()) return;
    if (
      !fields.pickUpLocation ||
      !fields.dropOffLocation ||
      !selectedDate ||
      !selectedDateof 
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill in all required fields: Pickup Location, Pickup Date, and Dropoff Date.',
      });
      return false;
    }

    const formData = {
      pickUpLocation: fields.pickUpLocation,
      dropOffLocation: fields.dropOffLocation,
      pickupDate: selectedDate,
      dropoffDate: selectedDateof,
      // Add other form fields as needed
    };
   


    // Send a request to your API with the form data
    getFleetsPickupFilter(formData)
      .then((response) => {
        // console.log(response.data.data);
        // Assuming the response.data.data contains the updated fleet informatio
        const scrollToPosition = window.innerWidth <= 480 ? 1600 : 700;
        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        if(response.data.data == undefined){          
          setCarProducts([]);
          return;
        }
        const updatedFleetData = response.data.data;
        // Map the updated fleet data to your desired format
       
        const updatedCarProducts = updatedFleetData.map((fleet) => ({
          id: fleet.id,
          name: `Product ${fleet.id}`,
          description: fleet.description || `Description of Product ${fleet.id}`,
          imageUrl: fleet.thumbnail_image || '', // Use your image property from the fleet data
          carImageUrl: fleet.main_image || '',
          brand: fleet.brand || '',
          model: fleet.model || '',
          type: fleet.type || '',
          daily_rate: fleet.daily_rate || 0,
          weekly_rate: fleet.weekly_rate || 0,
          monthly_rate: fleet.monthly_rate || 0,
          seats: fleet.passengers || 0,
          bags: fleet.bags || 0,
          doors: fleet.doors || 0,
          transmission: fleet.transmission || '',
          fuel_type: fleet.fuel_type || '',
        }));

        // Update the state with the mapped fleet data
        setCarProducts(updatedCarProducts);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };




  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateof, setSelectedDateof] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [locations, setLocations] = useState([]);

  // Function to toggle modal visibility
  const toggleModal = (product) => {
    setSelectedProduct(product);
    setIsModalVisible(!isModalVisible);
  };




    const [filterState, setFilterState] = useState({
      carType: [],
      seats: [],
      transmission: [],
      bags: [],
      engine: [],
      doors: [],
    });
  
    const handleCheckboxChange = (category, value) => {
      setFilterState((prevFilterState) => {
        const updatedCategory = [...prevFilterState[category]];
  
        if (updatedCategory.includes(value)) {
          updatedCategory.splice(updatedCategory.indexOf(value), 1);
        } else {
          updatedCategory.push(value);
        }
  
        return {
          ...prevFilterState,
          [category]: updatedCategory,
        };
      });
    };
  
    const handleLeftFilter = () => {
      // You can make an API call with filterState

      const checkoutData = {
        filterState: filterState,
        pickupLocation: fields.pickUpLocation,
        dropOffLocation: fields.dropOffLocation,
        pickupDate: selectedDate,
        dropoffDate: selectedDateof,
      };  
      

      getFleetsLeftFilter(checkoutData)
      .then((response) => {
        // return;
        // Assuming the response.data.data contains the updated fleet information

        const scrollToPosition = window.innerWidth <= 480 ? 1600 : 700;
        window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });

        if(response.data.data == undefined){          
          setCarProducts([]);
          return;
        }
        const updatedFleetData = response.data.data;
          
        // Map the updated fleet data to your desired format
        const updatedCarProducts = updatedFleetData.map((fleet) => ({
          id: fleet.id,
          name: `Product ${fleet.id}`,
          description: fleet.description || `Description of Product ${fleet.id}`,
          imageUrl: fleet.thumbnail_image || '', // Use your image property from the fleet data
          carImageUrl: fleet.main_image || '',
          brand: fleet.brand || '',
          model: fleet.model || '',
          type: fleet.type || '',
          daily_rate: fleet.daily_rate || 0,
          weekly_rate: fleet.weekly_rate || 0,
          monthly_rate: fleet.monthly_rate || 0,
          seats: fleet.passengers || 0,
          bags: fleet.bags || 0,
          doors: fleet.doors || 0,
          transmission: fleet.transmission || '',
          fuel_type: fleet.fuel_type || '',
        }));
        // Update the state with the mapped fleet data
        setCarProducts(updatedCarProducts);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
      // Call your API function here with filterState
    };


    useEffect(() => {
      // Fetch the content_faq data from the API
      getLocations()
        .then((response) => {
          setLocations(response.data.data);

          
        })
        .catch((error) => {
          console.error("Error fetching content_faq data:", error);
        });
    }, []);


  

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
      const inputRef = useRef(null);
    
      useEffect(() => {
        if (inputRef.current) {
          inputRef.current.blur(); // Blur the input on mount
        }
      }, []);
    
      return (
        <input
          type="text"
          value={value}
          onClick={onClick}
          placeholder="d-m-Y"
          ref={inputRef}
          readOnly
        />
      );
    });



    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setLongFormData({
        ...longformData,
        [name]: value,
      });
    };
  
    const validateForm = () => {
      const errors = {};
      if (!longformData.fullName.trim()) {
        errors.fullName = "Full Name is required";
      }
      if (!longformData.emailAddress.trim()) {
        errors.emailAddress = "Email Address is required";
      } else if (!isValidEmail(longformData.emailAddress)) {
        errors.emailAddress = "Invalid email address";
      }
      if (!longformData.companyName.trim()) {
        errors.companyName = "Company Name is required";
      }
      if (!longformData.services.trim()) {
        errors.services = "Services field is required";
      }
      if (!longformData.message.trim()) {
        errors.message = "Message field is required";
      }
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    const isValidEmail = (email) => {
      // Basic email validation, you can use a library for more thorough validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
        setLoading(true);
        // API call if form is valid
        createLongTermForm(longformData)
          .then((response) => {
            // Handle success with SweetAlert
            Swal.fire({
              icon: 'success',
              title: response.data.message,
              text: 'Your message has been sent!',
            });
  
            // Clear the form fields
            setLongFormData({
              fullName: "",
              emailAddress: "",
              companyName: "",
              services: "",
              message: "",
            });
  
          })
          .catch((error) => {
  
            if (error.response && error.response.status === 422) {
              // Validation error, update form errors state
              const validationErrors = error.response.data.errors;
              setFormErrors(validationErrors);
              Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please check the form fields for errors.',
              });
  
            } else {
              // Other types of errors
              Swal.fire({
                icon: 'error',
                title: 'Error submitting form',
                text: 'An error occurred while submitting the form.',
              });
             
            }
            console.error("Error submitting form:", error);
          }).finally(() => {
            setLoading(false); // Set loading back to false when the request is complete
          });;
      }
    };

  return (
    <div className="page-fleetview">
      <Header />
      <div className="discover-feet">
        <Container fluid>
          <Row>
           
            <Col lg="3" md="3" xs="12" className='mob-none'>
              <div className="filter-section mb-4 ">
                <div className="card">
                  <div className="card-body">
                    <div className="filter-heading">
                      <p>
                        <b>Filters </b>
                        <span>
                          <a href="">Clear Filters</a>
                        </span>
                      </p>
                    </div>
                    <hr />
                    <div className="checkbox-box">
                      <p>
                        <b>Car Type</b>
                      </p>
                      {["Small", "Medium", "SUV", "Bike"].map((type) => (
                        <label key={type}>
                          <input
                            type="checkbox"
                            value={type}
                            checked={filterState.carType.includes(type)}
                            onChange={() => handleCheckboxChange("carType", type)}
                          />
                          <span>{type}</span>
                        </label>
                      ))}
                      
                      <hr />
                      <p>
                        <b>Seats</b>
                      </p>
                      {["2-5 Seats", "6+ Seats"].map((seatOption) => (
                        <label key={seatOption}>
                          <input
                            type="checkbox"
                            value={seatOption}
                            checked={filterState.seats.includes(seatOption)}
                            onChange={() => handleCheckboxChange("seats", seatOption)}
                          />
                          <span>{seatOption}</span>
                        </label>
                      ))}

                      <hr />
                      <p>
                        <b>Automatic or Manual</b>
                      </p>
                      {["Automatic", "Manual"].map((transmissionOption) => (
                        <label key={transmissionOption}>
                          <input
                            type="checkbox"
                            value={transmissionOption}
                            checked={filterState.transmission.includes(transmissionOption)}
                            onChange={() => handleCheckboxChange("transmission", transmissionOption)}
                          />
                          <span>{transmissionOption}</span>
                        </label>
                      ))}

                      {/* ... (Continue with other filter sections) */}

                      {/* <hr />
                      <p>
                        <b>Bags</b>
                      </p>
                      {["1-2 Bags", "3+ Bags"].map((bagsOption) => (
                        <label key={bagsOption}>
                          <input
                            type="checkbox"
                            value={bagsOption}
                            checked={filterState.bags.includes(bagsOption)}
                            onChange={() => handleCheckboxChange("bags", bagsOption)}
                          />
                          <span>{bagsOption}</span>
                        </label>
                      ))} */}

                      {/* <p>
                        <b>Engine</b>
                      </p>
                      {["Petrol", "Diesel", "Gasoline"].map((engineOption) => (
                        <label key={engineOption}>
                          <input
                            type="checkbox"
                            value={engineOption}
                            checked={filterState.engine.includes(engineOption)}
                            onChange={() => handleCheckboxChange("engine", engineOption)}
                          />
                          <span>{engineOption}</span>
                        </label>
                      ))}       */}
                      <hr />
                      <p>
                        <b>Doors</b>
                      </p>
                      {["4 Doors", "4+ Doors"].map((doorsOption) => (
                        <label key={doorsOption}>
                          <input
                            type="checkbox"
                            value={doorsOption}
                            checked={filterState.doors.includes(doorsOption)}
                            onChange={() => handleCheckboxChange("doors", doorsOption)}
                          />
                          <span>{doorsOption}</span>
                        </label>
                      ))}
                      <hr />
                      <Button className="" id="filterButton" color="primary" onClick={handleLeftFilter}>
                        Filter
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="9" md="9">
              <div
                className="title-bar text-center text-light"
                style={{
                  backgroundImage: `url(${bgImg})`,
                  backgroundSize: "cover",
                  padding: "235px 0px",
                  borderRadius: "30px",
                  backgroundPosition: "center",
                }}
              >
                <Container>
                  <h4 className="mb-0" style={{ textTransform: "uppercase" }}>
                    BOOK THE BEST CAR AT THE LOWEST PRICE
                  </h4>

                  <h1 className="mb-0">
                    <span>Discover our Fleet</span>
                  </h1>
                </Container>
              </div>
              <div className="form-section-fleetview">
                <div className="section-form">
                  <Container>
                    <div
                      className="form-section"
                      // style={{ boxShadow: boxShadow }}
                    >
                      <Row style={{ alignItems: "flex-end" }}>
                        <Col lg="2" md="6" className="mb-3">
                        <FormGroupSelect
                          label="Pick-up Location"
                          onChange={handlePickupChange}
                          name="pickUpLocation"
                          value={fields?.pickUpLocation}
                          defaultName="Select Location"
                          list={locations}
                          fieldId="id"
                          fieldName="name"
                        />
                        </Col>
                        <Col lg="2" md="6" className="mb-3">
                        <FormGroupSelect
                          label="Drop-off Location"
                          onChange={handlePickupChange}
                          name="dropOffLocation"
                          value={fields?.dropOffLocation}
                          defaultName="Select Location"
                          list={locations}
                          fieldId="id"
                          fieldName="name"
                        />
                        </Col>
                        <Col lg="3" md="6" className="mb-3">
                          <div className="form-group">
                            <label className="form-label">Pick-up Date</label>

                            <DatePicker
                              selected={selectedDate}
                              onChange={handlePickupDateChange}
                              customInput={<CustomInput />}
                              showTimeSelect
                              timeFormat="h:mm:aa"
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat="dd-MM-yyyy h:mm:aa"
                              placeholder="dd-MM-yyyy"
                              minDate={new Date()}
                              disabledKeyboardNavigation
                              utcOffset={0}
                            />
                          </div>
                        </Col>
                        <Col lg="3" md="6" className="mb-3">
                          <div className="form-group">
                            <label className="form-label">Drop-off Date</label>
                            <DatePicker
                              selected={selectedDateof}
                              // onChange={(date) => setSelectedDateof(date)}
                              onChange={handleDropoffDateChange}
                              customInput={<CustomInput />}
                              showTimeSelect
                              timeFormat="h:mm:aa"
                              showYearDropdown
                              scrollableYearDropdown
                              dateFormat="dd-MM-yyyy h:mm:aa"
                              placeholder="dd-MM-yyyy"
                              minDate={selectedDate ? addDays(selectedDate, 1) : new Date()}
                              disabledKeyboardNavigation
                              utcOffset={0}
                            />
                          </div>
                        </Col>
                        
                        <Col lg="2" md="6" className="mb-3">
                          <Button className="btn-formPanel" color="primary" id="handlePickupBtn" onClick={handlePickupFilter}>
                          Check availability
                          </Button>
                        </Col>
                      </Row>
                      <Col xs="12" className='web-none'>
                      <div className="filter-section mb-4 ">
                        <div className="card">
                          <div className="card-body">
                            <div className="filter-heading">
                              <p>
                                <b>Filters </b>
                                <span>
                                  <a href="">Clear Filters</a>
                                </span>
                              </p>
                            </div>
                            <hr />
                            <div className="checkbox-box">
                              <p>
                                <b>Car Type</b>
                              </p>
                              {["Small", "Medium", "SUV", "Bike"].map((type) => (
                                <label key={type}>
                                  <input
                                    type="checkbox"
                                    value={type}
                                    checked={filterState.carType.includes(type)}
                                    onChange={() => handleCheckboxChange("carType", type)}
                                  />
                                  <span>{type}</span>
                                </label>
                              ))}
                              
                              <hr />
                              <p>
                                <b>Seats</b>
                              </p>
                              {["2-5 Seats", "6+ Seats"].map((seatOption) => (
                                <label key={seatOption}>
                                  <input
                                    type="checkbox"
                                    value={seatOption}
                                    checked={filterState.seats.includes(seatOption)}
                                    onChange={() => handleCheckboxChange("seats", seatOption)}
                                  />
                                  <span>{seatOption}</span>
                                </label>
                              ))}

                              <hr />
                              <p>
                                <b>Automatic or Manual</b>
                              </p>
                              {["Automatic", "Manual"].map((transmissionOption) => (
                                <label key={transmissionOption}>
                                  <input
                                    type="checkbox"
                                    value={transmissionOption}
                                    checked={filterState.transmission.includes(transmissionOption)}
                                    onChange={() => handleCheckboxChange("transmission", transmissionOption)}
                                  />
                                  <span>{transmissionOption}</span>
                                </label>
                              ))}

                              {/* ... (Continue with other filter sections) */}

                              {/* <hr />
                              <p>
                                <b>Bags</b>
                              </p>
                              {["1-2 Bags", "3+ Bags"].map((bagsOption) => (
                                <label key={bagsOption}>
                                  <input
                                    type="checkbox"
                                    value={bagsOption}
                                    checked={filterState.bags.includes(bagsOption)}
                                    onChange={() => handleCheckboxChange("bags", bagsOption)}
                                  />
                                  <span>{bagsOption}</span>
                                </label>
                              ))} */}

                              {/* <p>
                                <b>Engine</b>
                              </p>
                              {["Petrol", "Diesel", "Gasoline"].map((engineOption) => (
                                <label key={engineOption}>
                                  <input
                                    type="checkbox"
                                    value={engineOption}
                                    checked={filterState.engine.includes(engineOption)}
                                    onChange={() => handleCheckboxChange("engine", engineOption)}
                                  />
                                  <span>{engineOption}</span>
                                </label>
                              ))}       */}
                              <hr />
                              <p>
                                <b>Doors</b>
                              </p>
                              {["4 Doors", "4+ Doors"].map((doorsOption) => (
                                <label key={doorsOption}>
                                  <input
                                    type="checkbox"
                                    value={doorsOption}
                                    checked={filterState.doors.includes(doorsOption)}
                                    onChange={() => handleCheckboxChange("doors", doorsOption)}
                                  />
                                  <span>{doorsOption}</span>
                                </label>
                              ))}
                              <hr />
                              <Button className="" id="filterButton" color="primary" onClick={handleLeftFilter}>
                                Filter
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </Col>
                    </div>
                  </Container>
                </div>
              </div>
              <div className="car-product mt-2">
                <div className="row">
                  {/* Use map() to render each car product as a card */}
                  {carProducts.map((product) => (
                    <Col
                      lg="6"
                      md="6"
                      xs="12"
                      className="mb-4"
                      key={product.id}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="car-name-data">
                            <div className="row">
                              <div className="small-tags">
                                <span className="tag-btn">{product.type}</span>
                              </div>
                              <Col lg="6" md="6" xs="6">
                                <div className="car-data">
                                  <h6>{product.brand} {product.model}</h6>
                                  <p>
                                    or similar same group vehicle{" "}
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M6.56454 0C6.85644 0 7.14744 0 7.43933 0C7.47425 0.00805848 7.50917 0.0196985 7.54499 0.0223847C7.99179 0.0528278 8.43411 0.118191 8.86031 0.250708C11.6217 1.11207 13.3077 2.95657 13.8942 5.79315C13.9461 6.04565 13.9667 6.30531 14.0016 6.56229C14.0016 6.85418 14.0016 7.14518 14.0016 7.43708C13.9757 7.63496 13.9533 7.83373 13.9228 8.03072C13.69 9.57795 13.0248 10.9139 11.8984 11.9937C10.1882 13.6332 8.14221 14.2707 5.80257 13.8883C4.08343 13.6072 2.66424 12.7655 1.57545 11.4126C0.171483 9.66928 -0.283373 7.67615 0.167901 5.48603C0.727518 2.76853 3.04657 0.55514 5.78197 0.108342C6.04164 0.0662586 6.30309 0.0358155 6.56454 0ZM7.00149 0.979553C3.67692 0.979553 0.97733 3.68004 0.980912 7.00282C0.984493 10.3238 3.67065 13.0118 6.99433 13.0198C10.3144 13.0279 13.0176 10.3292 13.0212 7.00282C13.0256 3.68004 10.327 0.979553 7.00149 0.979553Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M6.83332 3.48657C7.28907 3.48836 7.65797 3.54925 8.00627 3.71669C8.82466 4.10887 9.10312 4.9559 8.67602 5.75906C8.48351 6.1199 8.20415 6.40374 7.88987 6.65713C7.80839 6.72339 7.72781 6.78965 7.6517 6.86128C7.44845 7.0511 7.34548 7.28749 7.33384 7.56416C7.33026 7.64564 7.33115 7.72802 7.32936 7.81039C7.32488 8.03693 7.22729 8.1578 7.00702 8.20078C6.90405 8.22048 6.80019 8.23033 6.69453 8.21779C6.42233 8.18735 6.3122 8.07364 6.28892 7.79696C6.22893 7.08334 6.45725 6.50044 7.05358 6.07692C7.18699 5.98201 7.31056 5.87278 7.42964 5.76085C7.56127 5.63819 7.65797 5.49045 7.69916 5.31047C7.76183 5.0338 7.65259 4.78399 7.40815 4.65147C6.94076 4.39808 6.35249 4.53507 6.05075 4.96754C5.85108 5.25406 5.66573 5.31674 5.39443 5.18781C5.14641 5.07051 5.04075 4.81264 5.13208 4.51627C5.2637 4.08648 5.57978 3.83309 5.97554 3.66207C6.27191 3.53224 6.58529 3.48747 6.83332 3.48657Z"
                                          fill="black"
                                        />
                                        <path
                                          d="M7.50134 9.57197C7.49865 9.95714 7.17887 10.2769 6.79639 10.2778C6.40942 10.2787 6.09322 9.95087 6.0977 9.55495C6.10218 9.1662 6.42017 8.85358 6.80714 8.85895C7.19141 8.86343 7.50403 9.18411 7.50134 9.57197Z"
                                          fill="black"
                                        />
                                      </svg>
                                    </span>
                                  </p>
                                </div>
                              </Col>
                              <Col lg="6" md="6" xs="6">
                                <div className="car-price">
                                  <p className="price">
                                    € {product.daily_rate} 
                                    {/* . <span className="font-small">23</span> */}
                                  </p>
                                  <p className="total-text">Price per day</p>
                                </div>
                              </Col>
                            </div>
                            <Row>
                              <Col md="12" lg="12">
                                <div className="image-product">
                                  <img src={product.imageUrl} alt="" />
                                </div>
                              </Col>
                            </Row>
                            <div className="car-specification">
                              <ul>
                                <li>
                                  <img src={usericon} alt="" />{product.seats} Seats
                                </li>
                                {/* <li>
                                  <img src={bagicon} alt="" />{product.bags} Bags
                                </li> */}
                                {product.type !== "Bike" && (
                                  <li>
                                    <img src={dooricon} alt="" />{product.doors} Doors
                                  </li>
                                   )}
                                {product.type !== "Bike" && (
                                  <li>
                                    <img src={manulicon} alt="" />
                                    {product.transmission}
                                  </li>
                                   )}
                                   {product.type !== "Bike" && (
                                  <li>
                                    <img src={petrolicon} alt="" />
                                    {product.fuel_type}
                                  </li>
                                    )}
                               
                              </ul>
                            </div>
                            <div className="car-select-btn">
                        
                            <button className="choose-btn" onClick={() => handleCheckoutChoose(product)}>
                                  Choose
                                </button>
                              <button onClick={() => toggleModal(product)}>Details</button>
                            </div>
                            {isModalVisible && (
                              <FullView onClose={toggleModal} selectedProduct={selectedProduct} onChoose={handleCheckoutChoose} />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </div>
              </div>
              <Faq />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact-form-bg">
        <Container fluid>
          <div className="extra-margin">
            <div className="extra-border">
              <Row className="contact-section">
                <Col lg="4" md="4">
                  <div className="right-section">
                    <div className="image-icon">
                      <img src={circlesupport} alt="" />
                      <h3>LONG TERM LEASE </h3>
                    </div>
                    <Row className="support-sec mt-5">
                      <Col md="2" lg="2">
                        <div className="support-image">
                          <img src={emailicon} alt="" />
                        </div>
                      </Col>
                      <Col md="8" lg="8">
                      <div className="support-text">
                          <p className="color-changetxt"> Send us a message</p>
                          <a href={`mailto:${siteSettings?.['site_email']}`} className='text-decoration-none'>
                            <p className="simple-txt">{siteSettings?.['site_email']}</p>
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <Row className="support-sec mt-3">
                      <Col md="2" lg="2">
                        <div className="support-image">
                          <img src={phoneicon} alt="" />
                        </div>
                      </Col>
                      <Col md="8" lg="8">
                        <div className="support-text">
                          <p className="color-changetxt"> Setup a call </p>
                          <a href={`tel:${siteSettings?.['site_phone']}`} className='text-decoration-none'>
                            <p className="simple-txt">
                              {siteSettings?.['site_phone']}
                            </p>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg="8" md="8">
                  <div className="contact-choice-section ">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <div className="form-group">
                          <Label>Full Name</Label>
                          <Input placeholder="Enter Your Name"   name="fullName"  value={longformData.fullName}  onChange={handleInputChange}  />
                        </div>
                        {formErrors.fullName && (
                            <div className="text-danger">{formErrors.fullName}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12">
                        <div className="form-group">
                          <Label>Email Address</Label>
                          <Input placeholder="Enter Your Email"  name="emailAddress"  value={longformData.emailAddress}  onChange={handleInputChange}/>
                        </div>
                        {formErrors.emailAddress && (
                            <div className="text-danger">{formErrors.emailAddress}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12" className="mt-3">
                        <div className="form-group">
                          <Label>Company Name</Label>
                          <Input placeholder="Enter Your Company"  name="companyName"  value={longformData.companyName}  onChange={handleInputChange}/>
                        </div>
                        {formErrors.companyName && (
                            <div className="text-danger">{formErrors.companyName}</div>
                          )}
                      </Col>
                      <Col lg="6" md="6" xs="12" className="mt-3">
                        <div className="form-group">
                          <Label>Services</Label>
                          <Input placeholder="Enter required services"  name="services" value={longformData.services}  onChange={handleInputChange}/>
                        </div>
                        {formErrors.services && (
                            <div className="text-danger">{formErrors.services}</div>
                          )}
                      </Col>
                      <Col lg="12" md="12" xs="12" className="mt-3">
                        <div className="form-group">
                          <Label>Enter Your Message</Label>
                          <Input
                            type="textarea"
                            rows={4}
                            cols={40}
                            placeholder="Enter your Message"
                            name="message"
                            value={longformData.message}
                            onChange={handleInputChange}
                          />
                        </div>
                        {formErrors.message && (
                            <div className="text-danger">{formErrors.message}</div>
                          )}
                      </Col>

                      <Col lg="12" className="mt-3">
                        <Button className="btn-contact-info" color="primary">
                          Send Message To Us
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
      {loading && (
          <div className="loading-spinner">
             <ClipLoader color="#36D7B7" loading={loading} size={50} />
            Loading...
          </div>
        )}
    </div>
  );
};

export default FleetView;
