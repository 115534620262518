// api.js
import axios from "axios";
import React, { useState, useEffect } from "react";


const currentDate = new Date();
const cutoffDate = new Date('2024-03-10'); // February 18, 2023
// Convert dates to UTC
const currentUTC = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const cutoffUTC = Date.UTC(cutoffDate.getFullYear(), cutoffDate.getMonth(), cutoffDate.getDate());


  let baseURL = "https://queencarentalmalta.com/admin/public/api";
  // let baseURL = "http://127.0.0.1:8000/api";

  // if (currentUTC  > cutoffUTC) {
  //     baseURL = "http://pay-the-remaining-amount/api";
  // }

const api = axios.create({
  // baseURL: "http://127.0.0.1:8000/api",
  // baseURL: "https://adminqueencar.soundgallery.pk/api", 
  // baseURL: "https://queencarentalmalta.com/admin/public/api",
  
  baseURL: baseURL,

  // You can add headers or other configurations here
});

export const getSample = () => api.get("/sample-get-api");
export const getPosts = () => api.get("/posts");
export const createPost = (data) => api.post("/posts", data);
export const getSiteSettings = () => api.get("/site-settings");
export const getFleets = () => api.get('fleets');
export const getLocations = () => api.get('locations');
export const getReservationsExtras = () => api.get('reservation-extras');




export const createLongTermForm = (formData) => api.post("/long-term-form", formData);
export const createNewsletter = (formData) => api.post("/newsletter-form", { email: formData });

export const getFleetsPickupFilter = (formData) => api.post("/fleets-pickupfilter", formData);
export const getFleetsLeftFilter = (formData) => api.post("/fleets-leftfilter", formData);
export const submitCheckoutForm = (formData) => api.post("/submit-checkout-form", formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const BookingModalForm = (formData) => api.post("/booking-check", formData);
export const createContactForm = (formData) => api.post("/contact-form", formData);
export const createShortContactForm = (formData) => api.post("/short-contact-form", { message: formData });



export const checkBeforeCheckout = (formData) => api.post("/check-before-checkout", formData);
export const submitReservationMail = (formData) => api.post("/submit-reservation-email", formData);
export const submitCoupon = (formData) => api.post("/submit-coupon", formData);



// Extract specific variables from the site settings response
export const getHomeBannerSlogan = async () => {
  try {
    const response = await getSiteSettings();
    const homeBannerSlogan = response.data.data['home_banner_slogan']//find(setting => setting.meta === 'home_banner_slogan');
    return homeBannerSlogan ? homeBannerSlogan.value : null;
  } catch (error) {
    console.error('Error fetching home banner slogan:', error);
    return null;
  }
};

// const [siteSettings, setSiteSettings] = useState(null);

getSiteSettings()
.then((response) => {
  // setSiteSettings(response.data.data);
  const transformedSettings = response.data.data.reduce((acc, setting) => {
    acc[setting.meta] = setting.value;
    return acc;
  }, {});
    localStorage.setItem("site_settings",JSON.stringify(transformedSettings));
})
.catch((error) => {
  console.error("Error fetching content_faq data:", error);
});




// Replace "/long-term-form" with your actual API endpoint for handling long-term form submissions


// Add more functions for other API calls
