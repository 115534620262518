import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import successpaymentIcon from "../assets/img/successpayment.gif";
import logo from "../assets/img/logo.png";

const SuccessPayment = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      //   backgroundColor: "#f4f4f4",
    },
    image: {
      borderRadius: "50%",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "24px",
      color: "#333",
      marginBottom: "10px",
    },
    text: {
      fontSize: "16px",
      color: "#666",
      textAlign: "center",
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/"; // Redirect to home page after 6 seconds
    }, 6000);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div style={styles.container}>
              {/* Make the logo clickable */}
              <Link to="/">
                <img src={logo} alt="Logo" className="pb-3" />
              </Link>
              <img src={successpaymentIcon} alt="Success" style={styles.image} />
              <h1
                className="payment-text"
                style={{ textAlign: "center", fontSize: "26px !important" }}
              >
                Payment Successful!
              </h1>
              <p style={styles.text}>
                Thank you for your payment. Your transaction was successful.
                <br />
                You have received an email with your booking information.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SuccessPayment;
