import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookiePopup = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="myCookieConsent"
            style={{
                background: '#ffffff',
                borderRadius: '12px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                color: '#444444',
                maxWidth: '100%',
                margin: '0 auto',
                fontSize: '16px',
                lineHeight: '1.6',
                textAlign: 'center',
                display: 'block'
            }}
            buttonStyle={{
                background: '#0088FF',
                color: '#ffffff',
                borderRadius: '6px',
                padding: '12px 24px',
                fontSize: '16px',
                border: 'none',
                cursor: 'pointer'
            }}
            expires={365} // Cookie expiration set to one year
        >
            <h4>We value your privacy</h4>
            <p>
                We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic.
                We also share information about your use of our site with our social media, advertising, and analytics partners.
            </p>
            <p>
                By clicking "Accept", you consent to our use of cookies and agree to our 
                <a href="/ourpolicy">Privacy Policy</a>.
            </p>
        </CookieConsent>
    );
};

export default CookiePopup;
