import React from "react";
import { Button, Container } from "reactstrap";
import { useNavigate } from 'react-router-dom';


const PremiumSection = () => {
  const navigate = useNavigate();
  const handleChangeFleet = () => {
    // Navigate to the "/FleetView" route
    navigate('/FleetView');
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  return (
    <div className="section-premium text-light">
      <Container fluid>
        <div className="inner-section-premium text-end">
          <p className="mb-0" data-aos="fade-right" data-aos-duration="1000">
            Faster, easier access to car rental services
          </p>
          <h3 className="mb-0" data-aos="fade-right" data-aos-duration="1000">
            Premium Car Rental
          </h3>
        

          <Button color="primary" data-aos="fade-right" data-aos-duration="1000" onClick={handleChangeFleet}>
            Let's Ride With Us
          </Button>

        </div>
      </Container>
    </div>
  );
};

export default PremiumSection;
