import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import failpayment from "../assets/img/failpayment.gif";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";

const FailPayment = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      //   backgroundColor: "#f4f4f4",
    },
    image: {
      borderRadius: "50%",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "24px",
      color: "#333",
      marginBottom: "10px",
    },
    text: {
      fontSize: "16px",
      color: "#666",
      textAlign: "center",
    },
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/"; // Redirect to home page after 6 seconds
    }, 6000);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div style={styles.container}>
             <Link to="/">
                <img src={logo} alt="Logo" className="pb-3" />
              </Link>
              <img src={failpayment} alt="failpayment" style={styles.image} />
              <h1
                className="payment-text"
                style={{ textAlign: "center", fontSize: "26px !important" }}
              >
                Payment Fail!
              </h1>
              <p style={styles.text}>Your transaction Fail.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FailPayment;