import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import bgImg from "../assets/img/hero_01.png";
import PickUpForm from "../components/Home/PickUpForm";
import PartnersSection from "../components/Home/PartnersSection";
import DiscoverSection from "../components/Home/DiscoverSection";
import CarSection from "../components/Home/CarSection";
import HowItWorks from "../components/Home/HowItWorks";
import PremiumSection from "../components/Home/PremiumSection";
import Footer from "../components/Footer";
import CustomerSatisfication from "../components/Home/CustomerSatisfication";
import GallerySection from "../components/Home/GallerySection";
import CitySections from "../components/Home/CitySections";
import Testimonial from "../components/Home/Testimonial";
import "aos/dist/aos.css";
import CityServiceSection from "../components/Home/CityServiceSection";


const Home = () => {
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])

  
  return (
    <div className="page-home">
      <Header />
      <PageTitle
        subHeading={siteSettings?.['home_banner_slogan']}
        heading={siteSettings?.['home_banner_heading']}
        bgImg={bgImg}
        gap="288px 0"
      />
      <PickUpForm btnText="Find a Car" boxShadow="none" />
      <PartnersSection />
      <DiscoverSection />
      <CarSection bg="#F4EFEF" />
      <HowItWorks />
      <CitySections />
      <CityServiceSection/>
      <CustomerSatisfication />
      <Testimonial />
      {/* <GallerySection /> */}
      <PremiumSection bg="#f4efef" />
      <Footer />
    </div>
  );
};

export default Home;
