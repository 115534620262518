import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";

import logoImg from "../assets/img/header_logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="page-header">
      <Container>
        <Navbar expand="md" className="navbar-custom p-0">
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />

          {/* Centered Logo */}
          <Link className="logo-image mx-auto" to="/">
            <img src={logoImg} alt="Car Rental" style={{ width: '70px' }} className="navbar-brand" />
          </Link>

          <Link className="nav-link nav-btn d-md-none" to="/FleetView">
                  Book 
                  Now
                </Link>
          <Collapse isOpen={isOpen} navbar className="nav main-menu">
            {/* Left Side Links */}
            <Nav className="mr-auto">
              <NavItem>
                <Link className="nav-link" to="/aboutus">
                  About Us
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/discover">
                  Discover Malta
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/FleetView">
                  Fleet
                </Link>
              </NavItem>
            </Nav>

            {/* Centered Logo */}
            <Link to="/" className="screen-logo mx-auto">
              <img src={logoImg} alt="Car Rental" />
            </Link>

            {/* Right Side Links */}
            <Nav className="ml-auto">
              <NavItem>
                <Link className="nav-link" to="/longtermform">
                  Long-Term Lease
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/contactus">
                  Contact Us
                </Link>
              </NavItem>
              <NavItem className="d-none d-md-block">
                <Link className="nav-link nav-btn " to="/FleetView">
                  Book Now
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
