import Swal from 'sweetalert2';
import React, { useState, useEffect, useRef  } from "react";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from 'reactstrap';



import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import bgImg from "../assets/img/img-backg-pages.png";
import Footer from "../components/Footer";
import line from "../assets/img/line-bg-golden.png";
import cardone from "../assets/img/Payment method icon-1.png";
import cardtwo from "../assets/img/Payment method icon-2.png";
import cardthree from "../assets/img/Payment method icon.png";
import apple from "../assets/img/ApplePay.png";
import googlepay from "../assets/img/gpay.png";
import paypal from "../assets/img/Payment method icon (1).png";
import secure from "../assets/img/Secure icon.png";
import imageCar from "../assets/img/modal-image-car.png";
import addImage from "../assets/img/ads-images.png";
import groupServices from "../assets/img/group-service.png";
import ticIcon from "../assets/img/tc-image.png";
import extraArrow from "../assets/img/extra-arrow.png";
import { submitCheckoutForm, submitReservationMail, getReservationsExtras, submitCoupon } from "../Api"; // Import the API function


import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

// import PhoneInput from 'react-phone-number-input/input';
// import 'react-phone-number-input/style.css';
// import 'react-phone-number-input/flags.css';
// import { getCountries } from 'react-phone-number-input/input';

const Checkout = () => {
  const [showPayment1, setShowPayment1] = useState(false);
  const [showPayment2, setShowPayment2] = useState(false);
  const [showPayment3, setShowPayment3] = useState(false);
  const [formBackgroundColor, setFormBackgroundColor] = useState("#FFFFFF"); // Initial background color
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fleetPrice, setFleetPrice] = useState(0.0);
  const [extraPrice, setExtraPrice] = useState(0.0);
  const [discountPrice, setDiscountPrice] = useState(0.0);
  const [discountPercent, setDiscountPercent] = useState(0.0);
  const [finalPrice, setFinalPrice] = useState(0.0);


  
  const [formattedPickupDate, setfformattedPickupDate] = useState(null);
  const [formattedDropoffDate, setfformattedDropoffDate] = useState(null)
  
  const [checkoutData, setCheckoutData] = useState(null);
  const [reservationExtras, setReservationExtras] = useState([]);
  const [couponMessage, setCouponMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [depositNowAmount, setDepositNowAmount] = useState(0);
  const [totalDaysForExtras, settotalDaysForExtras] = useState(0);

  const [col7Info, setCol7Info] = useState({});
  const [scrollMargin, setScrollMargin] = useState(0);
  const rightSideCardRef = useRef(null);
  const [aboveHeight, setAboveHeight] = useState(0);
  // const [col7Height, setAboveHeight] = useState(0);


  // const countries = getCountries();
  const [formData, setFormData] = useState({
    fullName: "",
    firstName: "",
    surName: "",
    email: "",
    phoneNumber: "",
    whatsappNumber: "",
    streetAddress: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    age: "",
    message: "",
    extras: [],   
    paymentMethods: {
      creditCard: false,
      googlePay: false,
      applePay: false,
    },
    depositOption: {
      depositOption: false,
      depositCollection: true,
    },
    agreement: {
      agreement: false,
    },
    fleet_id: "",
    pickupDate: "",
    dropoffDate: "",
    pickup_location: "",
    return_location: "",
    couponCode: "",
    idCard: null, 
    drivingLicense: null,
    driver_fullName: "",
    driver_firstName: "",
    driver_surName: "",
    driver_age: "",
    driver_idCard: null,
    driver_drivingLicense: null
  });

  // const location = useLocation();
  // const { product } = location.state;
  const location = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    // const { product } = location.state || {};
    // const { product, pickupLocation, pickupDate, dropoffDate } = location.state || {};
    
    const { checkoutData } = location.state || {};
    if (checkoutData) {
      setCheckoutData(checkoutData);
      // const formattedPickupDate = checkoutData.pickupDate.toLocaleString('en-US', {
      //   day: '2-digit',
      //   month: '2-digit',
      //   year: 'numeric',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   hour12: true,
        
      // });

      let pickup = new Date(checkoutData.pickupDate);

      const getAmPm = (hours) => (hours >= 12 ? 'PM' : 'AM');
      const get12HourFormat = (hours) => (hours % 12 || 12);
      
      const formattedPickupDate =
        pickup.getDate().toString().padStart(2, '0') + '-' +
        (pickup.getMonth() + 1).toString().padStart(2, '0') + '-' +
        pickup.getFullYear() + ' , ' +
        get12HourFormat(pickup.getHours()).toString().padStart(2, '0') + ':' +
        pickup.getMinutes().toString().padStart(2, '0') + ' ' +
        getAmPm(pickup.getHours());
        
      setfformattedPickupDate(formattedPickupDate);

      // const formattedDropoffDate = checkoutData.dropoffDate.toLocaleString('en-US', {
      //   day: '2-digit',
      //   month: '2-digit',
      //   year: 'numeric',
      //   hour: '2-digit',
      //   minute: '2-digit',
      //   hour12: true,
      // });

      let dropOff = new Date(checkoutData.dropoffDate);     
      
      const formattedDropoffDate =
        dropOff.getDate().toString().padStart(2, '0') + '-' +
        (dropOff.getMonth() + 1).toString().padStart(2, '0') + '-' +
        dropOff.getFullYear() + ' , ' +
        get12HourFormat(dropOff.getHours()).toString().padStart(2, '0') + ':' +
        dropOff.getMinutes().toString().padStart(2, '0') + ' ' +
        getAmPm(dropOff.getHours());
      setfformattedDropoffDate(formattedDropoffDate);

      formData.fleet_id = checkoutData.product.id;
      // const pickupDateForSend = new Date(checkoutData.pickupDate);
      // const dropoffDateForSend = new Date(checkoutData.dropoffDate);
      // formData.pickupDate = Math.floor(pickupDateForSend.getTime() / 1000); //checkoutData.pickupDate;
      // formData.dropoffDate = Math.floor(dropoffDateForSend.getTime() / 1000); // checkoutData.dropoffDate;
      
      formData.pickupDate = formattedPickupDate; //pickupDateForSend.toISOString();
      formData.dropoffDate = formattedDropoffDate;// dropoffDateForSend.toISOString();

      formData.pickup_location = checkoutData.pickupLocation;
      formData.return_location = checkoutData.dropOffLocation;
      

      //Fleet Charges

      const timeDifference = checkoutData.dropoffDate.getTime() - checkoutData.pickupDate.getTime();
      const totalHours = timeDifference / (1000 * 60 * 60);
     
      
      let dailyRate = checkoutData.product.daily_rate;
      let weeklyRate = checkoutData.product.weekly_rate;
      let upto15DaysRate = checkoutData.product.monthly_rate;

      var daysInWeek = 7;
      var totalDays = Math.ceil(totalHours / 24);
      settotalDaysForExtras(totalDays);
      var totalPeriods = Math.floor(totalDays / 3000);
      var remainingDays = totalDays % 3000;

      var periodsTotal = totalPeriods * upto15DaysRate;
      var weeklyTotal = Math.floor(remainingDays / daysInWeek) * weeklyRate;
      var dailyTotal = (remainingDays % daysInWeek) * dailyRate;
      var fleetTotal = periodsTotal + weeklyTotal + dailyTotal;
      setFleetPrice(fleetTotal.toFixed(2));


      // bring extras
      getReservationsExtras()
      .then((response) => {
        setReservationExtras(response.data.data);

      })
      .catch((error) => {
      });
      
    }
  }, [location]);

 
  useEffect(() => {


    // Calculate and format the final price
    const formattedFinalPriceBeforeDiscount = (parseFloat(fleetPrice)  ).toFixed(2);
    let formattedFinalPrice = formattedFinalPriceBeforeDiscount;
    // console.log("discountPrice", discountPrice)

    if(discountPrice != 0 && discountPercent==0){
      formattedFinalPrice =  (formattedFinalPriceBeforeDiscount - parseFloat(discountPrice) ).toFixed(2);

    }else{
      // console.log("discountPercent", discountPercent)

      if(discountPercent != 0){
        // // console.log("discountPercent", discountPercent)
        // console.log("formattedFinalPriceBeforeDiscount", formattedFinalPriceBeforeDiscount)
        let discountedAmountLocal = (formattedFinalPriceBeforeDiscount * discountPercent)/100;
        // console.log("discountedAmountLocal", discountedAmountLocal)
        setDiscountPrice(discountedAmountLocal);
        formattedFinalPrice = formattedFinalPrice - discountedAmountLocal ;
      }
     

    }

    // console.log("formattedFinalPrice", formattedFinalPrice)
    formattedFinalPrice = (parseFloat(formattedFinalPrice) + parseFloat(depositNowAmount) + parseFloat(extraPrice) ).toFixed(2);

    setFinalPrice(formattedFinalPrice);
  }, [extraPrice, fleetPrice, discountPrice, depositNowAmount]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollY = window.scrollY || window.pageYOffset;
  //     const newMargin = Math.min(0, scrollY - col7Height);
  //     // setScrollMargin(newMargin);
  //   };

  //   const updateCol7Height = () => {
  //     // const col7Height = document.getElementById("col7").offsetHeight;
  //     setCol7Height(1800);
  //   };


  //   window.addEventListener("scroll", handleScroll);
  //   window.addEventListener("resize", updateCol7Height);

  //   // Initial setup
  //   updateCol7Height();
  //   handleScroll();

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     window.removeEventListener("resize", updateCol7Height);
  //   };
  // }, [col7Height]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      
      const pageHeaderElement = document.querySelector(".page-header");
      const titleBarElement = document.querySelector(".title-bar");
      const pricingRowElement = document.querySelector(".pricing-row");
      const pageHeader = pageHeaderElement ? pageHeaderElement.clientHeight : 0;
      const titleBar = titleBarElement ? titleBarElement.clientHeight : 0;
      const pricingRow = pricingRowElement ? pricingRowElement.clientHeight : 0;
      const aboveHeightTemp = pageHeader + titleBar + pricingRow + 48;   
      if(scrollY > aboveHeightTemp) {
        const newMargin = scrollY-aboveHeightTemp; //Math.min(0, scrollY - col7Info.bottom);
        const rightsideCard = document.querySelector(".rightside-card") ?.clientHeight;
        const col7 = document.querySelector(".leftside-card") ?.clientHeight; 
        if(window.screen.width > 800 ){ //480 
          if((newMargin+rightsideCard) <= col7){
            setScrollMargin(newMargin);
          }else{
            setScrollMargin(col7-rightsideCard);
          }
        }     
        
        
      
      }else{
        setScrollMargin(0);
      }
    

      //
    };
    

    const updateCol7Info = () => {

      const pageHeaderElement = document.querySelector(".page-header");
      const titleBarElement = document.querySelector(".title-bar");
      const pricingRowElement = document.querySelector(".pricing-row");


      const pageHeader = pageHeaderElement ? pageHeaderElement.clientHeight : 0;
      const titleBar = titleBarElement ? titleBarElement.clientHeight : 0;
      const pricingRow = pricingRowElement ? pricingRowElement.clientHeight : 0;
      const aboveHeightTemp = pageHeader + titleBar + pricingRow + 48;
      setAboveHeight(aboveHeightTemp);
    //   const col7Element = document.getElementById("col7");
    //   if (col7Element) {
    //     const col7Rect = col7Element.getBoundingClientRect();

    //     const pageHeader = document.querySelector(".page-header").clientHeight
    //     const titleBar = document.querySelector(".title-bar").clientHeight
    //     const pricingRow = document.querySelector(".pricing-row").clientHeight
        
        

    //     setAboveHeight(pageHeader + titleBar + pricingRow + 48);
        

    //     setCol7Info(col7Rect);

        
    //     // if (headerRef.current && pageTitleRef.current && rowTopRef.current) {
    //     //   const headerHeight = headerRef.current.clientHeight;
    //     //   const pageTitleHeight = pageTitleRef.current.clientHeight;
    //     //   const rowHeight = rowTopRef.current.clientHeight;
    
    //     // }
    //   }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateCol7Info);

    // Initial setup
    updateCol7Info();
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateCol7Info);
    };
  }, [col7Info.bottom]);

  if (!checkoutData) {
    // setTimeout(() => {
          return <div>No Car selected</div>;
    // }, 1000);
  }


  




  const handleCheckboxChange = (section, key, isChecked) => {

    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: !prevFormData[section][key],
      },
    }));

    updateDepositNowAmount(section, key, isChecked);
    checkDepositCheckbox(section, key, isChecked);
    checkPaymentCheckbox(section, key, isChecked);
  };

  const updateDepositNowAmount = (section, key, isChecked) => {
    if (section == "depositOption" && key == "depositOption") {
      if(isChecked){
        setDepositNowAmount(400);
      } else {
        setDepositNowAmount(0);
      }  
    } 

    if(section == "depositOption" && key== "depositCollection" && isChecked){
      setDepositNowAmount(0);
    }
   
  };

  const checkDepositCheckbox = (section, key, isChecked) => {
    if (section == "depositOption" && key == "depositOption" && isChecked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["depositOption"]: {
          ...prevFormData["depositOption"],
          ["depositCollection"]: false,
        },
      }));
    } else if (section == "depositOption" && key == "depositCollection" && isChecked){
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["depositOption"]: {
          ...prevFormData["depositOption"],
          ["depositOption"]: false,
        },
      }));    
    }else if (section == "depositOption" && key == "depositCollection" && !isChecked){
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["depositOption"]: {
          ...prevFormData["depositOption"],
          ["depositOption"]: true,
        },
      }));    

      }
      else if (section == "depositOption" && key == "depositOption" && !isChecked){
        setFormData((prevFormData) => ({
          ...prevFormData,
          ["depositOption"]: {
            ...prevFormData["depositOption"],
            ["depositCollection"]: true,
          },
        }));    
  
        }
  };

  const checkPaymentCheckbox = (section, key, isChecked) => {
    
    if (section == "paymentMethods" && key == "creditCard" && isChecked) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["paymentMethods"]: {
          ...prevFormData["paymentMethods"],
          ["googlePay"]: false,
          ["applePay"]: false,
        },
      }));
    } else if (section == "paymentMethods" && key == "googlePay" && isChecked){
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["paymentMethods"]: {
          ...prevFormData["paymentMethods"],
          ["creditCard"]: false,
          ["applePay"]: false,
        },
      }));    
    } else if (section == "paymentMethods" && key == "applePay" && isChecked){
      setFormData((prevFormData) => ({
        ...prevFormData,
        ["paymentMethods"]: {
          ...prevFormData["paymentMethods"],
          ["creditCard"]: false,
          ["googlePay"]: false,
        },
      }));    }
  };


  const handleExtraCheckboxChange = (extraId, cost, isChecked) => {
    setFormData((prevFormData) => {
      const isSelected = prevFormData.extras.includes(extraId);
  
      // If the extra is selected, remove it from the array, otherwise add it
      const updatedExtras = isSelected
        ? prevFormData.extras.filter((id) => id !== extraId)
        : [...prevFormData.extras, extraId];

        if(extraId == 5) {
          cost = cost * totalDaysForExtras;
        }
  
      // Calculate the updated extraPrice based on the selected extras
      setExtraPrice((prevPrice) => {
        const updatedPrice = isSelected
          ? prevPrice - parseFloat(cost)
          : prevPrice + parseFloat(cost);
  
        return Number(updatedPrice.toFixed(2)); // Format to two decimal places
      });
  
      return {
        ...prevFormData,
        extras: updatedExtras,
      };
    });
    //means Extra Driver
    if(extraId == "4" && isChecked) {
      document.getElementById("driverInfo").classList.remove("d-none");
    
    }


    if(extraId == "4" && isChecked == false) {
      document.getElementById("driverInfo").classList.add("d-none");
    }
  };

 


  const handleInputChange = (key, value) => {
    

    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  const handleBlur = (key, value) => {
    if(key == "age" && value < 25) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: "Age must be at least 25!",
      });
    } 
    if(key == "driver_age" && value < 25) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: "Driver Age must be at least 25!",
      });
    } 
    
   
  };

  const handleFileChange = (e, fieldName) => {

    const file = e.target.files[0];
    if (file.size > 4 * 1024 * 1024) {
        Swal.fire({
          icon: 'error',
          title: 'File Size',
          text: "File size should not be greater than 4mb!",
        });
        // Clear the file input
        e.target.value = null;
        return;
    }
    
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: file,
    }));
  };

  const isValidEmail = (email) => {
    // Basic email validation, you can use a library for more thorough validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    // alert(formData.fleet_id);
    // return;
    // Validation
    let error = '';
    if (!formData.firstName.trim()) {
      error = "First Name is required!";
    } else if (!formData.email.trim() || !isValidEmail(formData.email.trim())) {
      error = "Valid email is required!";
    } else if (!formData.phoneNumber.trim() ) {
      error = "Valid phone number is required!";
    } else if (!formData.streetAddress.trim()) {
      error = "Street Address is required!";
    } else if (!formData.country.trim()) {
      error = "Country is required!";
    } else if (!formData.city.trim()) {
      error = "City is required!";
    } else if (!formData.zipCode.trim()) {
      error = "Zip Code is required!";
    } else if (!formData.age.trim() || parseInt(formData.age, 10) < 25) {
      error = "Age must be at least 25!";
    } else if (!(formData.paymentMethods.creditCard || formData.paymentMethods.googlePay || formData.paymentMethods.applePay)) {
      error = "Select at least one payment option!";
    } else if (!formData.agreement.agreement) {
      error = "Terms & Condition must be accepted!";
    } else if (formData.idCard == null ) {
      error = "ID Card is required!";
    } else if (formData.drivingLicense == null ) {
      error = "Driving License is required!";
    }
  
    if(formData.extras.includes(4)){
      if (!formData.driver_firstName.trim()) {
        error = "Driver First Name is required!";
      } else if (!formData.driver_age.trim()) {
        error = "Driver Valid age is required!";
      } else if (formData.driver_idCard == null ) {
        error = "Driver ID Card is required!";
      } else if (formData.driver_drivingLicense == null ) {
        error = "Driver Driving License is required!";
      }else if(parseInt(formData.driver_age, 10) < 25){
        error = "Driver Age must be at least 25!";
      }
    }

    if (error !== '') {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: error,
      });
      return;
    }
   
  
    setLoading(true);
    submitCheckoutForm(formData)
    .then((response) => {


      const reservation_id = response?.data?.data?.reservation_id;
    
      if(reservation_id) {
        const reservation = {
          reservation_id : reservation_id
        };

        // submitReservationMail(reservation)
        // .then((response) => {
        // })
      }

      setLoading(false); 
    
      const sessionUrl = response?.data?.data?.session_url;

      if (sessionUrl) {
        setTimeout(() => {
          window.location.href = sessionUrl;
        }, 200);
       
        
      }
     
      
       // Clear the form fields
      setFormData({
        fullName: "",
        firstName: "",
        surName: "",
        email: "",
        phoneNumber: "",
        whatsappNumber: "",
        streetAddress: "",
        country: "",
        flightNumber: "",
        city: "",
        state: "",
        zipCode: "",
        age: "",
        message: "",
        extras: [],
        // extras: {
        //   extra1: false,
        //   extra2: false,
        //   extra3: false,
        //   extra4: false,
        // },
        paymentMethods: {
          creditCard: false,
          googlePay: false,
          applePay: false,
        },
        depositOption: {
          depositOption: false,
          depositCollection: false,
        },
        agreement: {
          agreement: false,
        },
        fleet_id: "",
        pickupDate: "",
        dropoffDate: "",
        pickup_location: "",
        return_location: "",
        couponCode: "",
        idCard: null, 
        drivingLicense: null,
        driver_fullName: "",
        driver_firstName: "",
        driver_surName: "",
        driver_age: "",
        driver_idCard: null,
        driver_drivingLicense: null
      });

      
      
    })
    .catch((error) => {

      if (error.response && error.response.status === 422) {
        // Validation error, update form errors state
        // // console.log(error.response.data.errors);
        const firstErrorKey = Object.keys(error.response.data.errors)[0]; // Get the first key
        const firstErrorMessage = error.response.data.errors[firstErrorKey][0]; // Get the first error message
        // console.log(error);
        // console.log(firstErrorKey);
        // console.log(firstErrorMessage);
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: firstErrorMessage,
          // text: 'Please check the form fields for errors.',
        });

      } else {
        // console.log(error);
        // Other types of errors
        Swal.fire({
          icon: 'error',
          title: 'Error submitting form',
          text: 'An error occurred while submitting the form.',
        });
       
      }
      console.error("Error submitting form:", error);
    }).finally(() => {
      setLoading(false); // Set loading back to false when the request is complete
    });;
    // if (!formData.fullName || !formData.email || !formData.phoneNumber || !formData.streetAddress  ) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: "Validation Error",
    //     text: 'Please fill in required fields!',
    //   });
    //   return;
    // }

    // if(formData.age < 21){
    //   Swal.fire({
    //     icon: 'error',
    //     title: "Validation Error",
    //     text: 'Age should be greater then 21',
    //   });
    //   return;
    // }

   


    // Additional validation logic (e.g., age validation)

    // API call
    // Add your API endpoint and logic here to send formData to the server
  };

  const handleCouponSubmit = () => {
    // Replace the following with your coupon validation logic
    // For example, you might want to check the coupon code against a server API
    const isValidCoupon = true; // Replace with your coupon validation result
    setDiscountPrice(0);
    setDiscountPercent(0);
    setCouponMessage('');

    const coupon = {
      coupon_code : formData.couponCode
    };

    // setFormData({
    //   couponCode: couponCode
    // });
    submitCoupon(coupon)
      .then((response) => {
        // console.log("response", response)
        if (response.data && response.data.data) {
          var data = response.data.data;

          if (data.percent == null) {
            // Ensure that data.amount is treated as a number before using toFixed(2)
            setDiscountPrice(Number(data.amount).toFixed(2));
            setDiscountPercent(0);
          } else {
            
            // setFinalPrice(discountPrice+finalPrice);
            
          //  // console.log("here")
            const formattedFinalPrice = (parseFloat(fleetPrice)  );

            let discountPriceLocal = formattedFinalPrice * data.percent/100;
            // + parseFloat(depositNowAmount)
            setDiscountPercent(data.percent);
            setDiscountPrice(discountPriceLocal);

            
         

            // const formattedFinalPrice = (parseFloat(fleetPrice) + parseFloat(extraPrice) + parseFloat(depositNowAmount) - parseFloat(discountPriceLocal)).toFixed(2);
            // setFinalPrice(formattedFinalPrice);
            
          }

          // If data exists in the response, consider the coupon applied successfully
          setCouponMessage('Coupon applied successfully');
        } else {
          setDiscountPercent(0);
          setDiscountPrice(0);
          // If data does not exist, handle it accordingly (you might want to show an error message)
          setCouponMessage('Error applying coupon');
        }
      })
      .catch((error) => {
        console.error("Error submitting coupon", error);
        setCouponMessage('Error applying coupon');
      });


  };


  

  
  return (
    <>
      <div className="page-Checkout">
        <Header/>
        <PageTitle heading="Checkout" bgImg={bgImg} gap="180px 0" />
        <div className="check-out-form py-5">
          <Container>
            <Row className="pricing-row">
              <Col lg="12" md="12">
                <div className="checkout-text-heading mb-4">
                  <h3>
                    {" "}
                    Plan & <span className="procinig-txt">Pricing</span> Details
                  </h3>
                </div>
              </Col>
            </Row>
            <Row className="equal-height-row">
              <Col lg="7" md="7" id="col7">
                <div className="leftside-card mb-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-data-section">
                        <h5 className="address-txt">Address</h5>
                        <img src={line} alt="" />
                        <p>Please enter your details to checkout </p>
                      </div>
                      <div className="contact-choice-section">
                        <Container>
                          <Row>
                            {/* ... other form fields ... */}
                            {/* Full Name */}
                            <Col lg="6" md="6" xs="12">
                              <div className="form-group">
                                <Label>First Name: <span className="text-danger">*</span></Label>
                                <Input
                                  placeholder="First Name"
                                  value={formData.firstName}
                                  onChange={(e) => handleInputChange("firstName", e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6" md="6" xs="12">
                              <div className="form-group">
                                <Label>Surname: </Label>
                                <Input
                                  placeholder="Surname"
                                  value={formData.surName}
                                  onChange={(e) => handleInputChange("surName", e.target.value)}
                                />
                              </div>
                            </Col>

                            {/* Email */}
                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>
                                  Email:<span className="text-danger">*</span>
                                </Label>
                                <Input
                                  placeholder="Enter Your Email"
                                  value={formData.email}
                                  onChange={(e) => handleInputChange("email", e.target.value)}
                                />
                              </div>
                            </Col>

                            {/* Phone Number */}
                            {/* <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>
                                  Phone Number
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  placeholder="Phone Number"
                                  value={formData.phoneNumber}
                                  onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
                                />
                              </div>
                            </Col> */}

                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>
                                  Phone Number
                                  <span className="text-danger">*</span>
                                </Label>
                                <PhoneInput
                                  defaultCountry="mt"
                                  value={formData.phoneNumber}
                                  onChange={(value) => handleInputChange("phoneNumber", value)}
                                />
{/* 
                                <PhoneInput
                                      containerStyle={{ width: "100%" }} // Adjust container width if needed
                                      inputStyle={phoneInputStyles} // Apply the specified styles to the input field
                                      buttonStyle={{ height: "40px" }} // Adjust button height to match input height
                                      defaultCountry="mt"
                                      value={formData.phoneNumber}
                                      onChange={(value) => handleInputChange("phoneNumber", value)}
                                    /> */}
                                
                                {/* <PhoneInput
                                  international
                                  defaultCountry="US" // Set default country to Malta
                                  value={formData.phoneNumber}
                                  onChange={(value) => handleInputChange("phoneNumber", value)}
                                /> */}
                              </div>
                            </Col>

                            {/* Whatsapp Number */}
                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>Whatsapp Number</Label>
                                <Input
                                  placeholder="Whatsapp Number"
                                  value={formData.whatsappNumber}
                                  onChange={(e) => handleInputChange("whatsappNumber", e.target.value)}
                                />
                              </div>
                            </Col>

                           
                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label for="flightNumber">Flight or Ferry details:</Label>
                                <Input
                                  type="text"
                                  id="flightNumber"
                                  name="flightNumber"
                                  placeholder="Enter Flight or Ferry details"
                                  value={formData.flightNumber}
                                  onChange={(e) => handleInputChange("flightNumber", e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label for="age">Age:<span className="text-danger">*</span></Label>
                                <Input
                                  type="number"
                                  id="age"
                                  name="age"
                                  placeholder="Enter Your Age"
                                  value={formData.age}
                                  onChange={(e) => handleInputChange("age", e.target.value)}
                                  onBlur={(e) => handleBlur("age", e.target.value)} 
                                />
                              </div>
                            </Col>
                            <Col lg="6" md="6" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label for="streetAddress">
                                  Street Address <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  id="streetAddress"
                                  name="streetAddress"
                                  placeholder="Street Address"
                                  value={formData.streetAddress}
                                  onChange={(e) => handleInputChange("streetAddress", e.target.value)}
                                />
                              </div>
                            </Col>
                           

                              <Col lg="6" md="6" xs="12" className="mt-3">
                                <div className="form-group">
                                  <Label for="country">
                                    Country <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="country"
                                    name="country"
                                    placeholder="Country"
                                    value={formData.country}
                                    onChange={(e) => handleInputChange("country", e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg="6" md="6" xs="12" className="mt-3">
                                <div className="form-group">
                                  <Label for="city">
                                    City <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder="City"
                                    value={formData.city}
                                    onChange={(e) => handleInputChange("city", e.target.value)}
                                  />
                                </div>
                              </Col>

                              <Col lg="6" md="6" xs="12" className="mt-3">
                                <div className="form-group">
                                  <Label for="state">State</Label>
                                  <Input
                                    type="text"
                                    id="state"
                                    name="state"
                                    placeholder="State"
                                    value={formData.state}
                                    onChange={(e) => handleInputChange("state", e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col lg="6" md="6" xs="12" className="mt-3">
                                <div className="form-group">
                                  <Label for="zipCode">
                                    Zip Code <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    id="zipCode"
                                    name="zipCode"
                                    placeholder="Zip Code"
                                    value={formData.zipCode}
                                    onChange={(e) => handleInputChange("zipCode", e.target.value)}
                                  />
                                </div>
                              </Col>
                           

                            <Col lg="12" md="12" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label for="message">Enter Your Message</Label>
                                <Input
                                  type="textarea"
                                  id="message"
                                  name="message"
                                  placeholder="Enter your Message"
                                  onChange={(e) => handleInputChange("message", e.target.value)}

                                />
                              </div>
                            </Col>


                            <Col lg="12" md="12" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>ID card<span className="text-danger">*</span> <i>(max 4mb)</i></Label>
                                <Input
                                  type="file"
                                  onChange={(e) => handleFileChange(e, 'idCard')}
                                />
                              </div>
                            </Col>

                            <Col lg="12" md="12" xs="12" className="mt-3">
                              <div className="form-group">
                                <Label>Driving license<span className="text-danger">*</span> <i>(max 4mb)</i></Label>
                                <Input
                                  type="file"
                                  onChange={(e) => handleFileChange(e, 'drivingLicense')}
                                />
                              </div>
                            </Col>

                            {/* Extras */}
                            <Col md="12" className="mt-4">
                              <Label>Extras</Label>
                            </Col>

                     

                           
                            {reservationExtras.map((extra) => (
                              <Col md="4" xs="4" key={extra.id}>
                                <FormGroup check>
                                  <Input
                                    type="checkbox"
                                    checked={formData.extras.includes(extra.id)}
                                    onChange={(e) => handleExtraCheckboxChange(extra.id, extra.cost, e.target.checked)}
                                  />
                                  <Label check>{extra.name} (€{extra.cost})</Label>
                                </FormGroup>
                              </Col>
                            ))}

                            <Col md="12" className="mt-4 d-none p-3" id="driverInfo" style={{ backgroundColor: "#f4efef"}}>
                              <Label>Extra Driver Information</Label>
                              <br />

                              <span>
                                <Row>
                                  <Col lg="4" md="4" xs="12">
                                    <div className="form-group">
                                      <Label>First Name: <span className="text-danger">*</span></Label>
                                      <Input
                                        placeholder="First Name"
                                        value={formData.driver_firstName}
                                        onChange={(e) => handleInputChange("driver_firstName", e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="4" md="4" xs="12">
                                    <div className="form-group">
                                      <Label>Surname: </Label>
                                      <Input
                                        placeholder="Surname"
                                        value={formData.driver_surName}
                                        onChange={(e) => handleInputChange("driver_surName", e.target.value)}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="4" md="4" xs="12">
                                    <div className="form-group">
                                      <Label for="age">Age:<span className="text-danger">*</span></Label>
                                      <Input
                                        type="number"
                                        id="age"
                                        name="age"
                                        placeholder="Enter Driver Age"
                                        value={formData.driver_age}
                                        onChange={(e) => handleInputChange("driver_age", e.target.value)}
                                        onBlur={(e) => handleBlur("driver_age", e.target.value)} 
                                      />
                                    </div>
                                  </Col>


                                  <Col lg="12" md="12" xs="12" className="mt-3">
                                    <div className="form-group">
                                      <Label>ID card<span className="text-danger">*</span> <i>(max 4mb)</i></Label>
                                      <Input
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'driver_idCard')}
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="12" md="12" xs="12" className="mt-3">
                                    <div className="form-group">
                                      <Label>Driving license<span className="text-danger">*</span> <i>(max 4mb)</i></Label>
                                      <Input
                                        type="file"
                                        onChange={(e) => handleFileChange(e, 'driver_drivingLicense')}
                                      />
                                    </div>
                                  </Col>
                                </Row>


                                {/* <b>Fullname: </b> John Michael<br />
                                <b>Age:</b> 27<br />
                                <b>ID Card:</b> <a target='_blank' href='https://images.pexels.com/photos/45113/pexels-photo-45113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' >Click here for Id Card</a><br />
                                <b>Driver License: </b> <a target='_blank'href="https://images.pexels.com/photos/45113/pexels-photo-45113.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1">Click here for driver license</a> */}
                              </span>
                            </Col>

                            

                            
                            {/* Payment Methods */}
                            <Col lg="12" md="12">
                              <div className="paymentheading">
                                <p>Payment</p>
                              </div>
                            </Col>

                            {/* Credit Card */}
                            <Col lg="12" md="12">
                              <div
                                className={`check-payment ${
                                  formData.paymentMethods.creditCard ? "selected" : ""
                                }`}
                                style={{
                                  backgroundColor: formData.paymentMethods.creditCard
                                    ? formBackgroundColor
                                    : "",
                                  padding: "20px",
                                }}
                              >
                                <Row>
                                  <Col md="7" lg="7" xs="6">
                                    <label>
                                      <Input
                                        type="checkbox"
                                        className="mt-4"
                                        checked={formData.paymentMethods.creditCard}
                                        onChange={(e) => handleCheckboxChange("paymentMethods", "creditCard", e.target.checked)}
                                      />
                                      Pay with Credit Card
                                    </label>
                                  </Col>
                                  <Col md="5" lg="5" xs="6">
                                    <div className="image-section">
                                      <span>
                                          <img src={cardone} alt="" />
                                        </span>

                                        <span>
                                          <img src={cardthree} alt="" />
                                        </span>
                                        <span>
                                          <img src={cardtwo} alt="" />
                                        </span>
                                    </div>
                                  </Col>
                                </Row>

                                {formData.paymentMethods.creditCard && (
                                  <div>
                                    <p>Seamless transaction with Stripe</p>
                                  </div>
                                )}
                              </div>
                            </Col>

                            {/* Google Pay */}
                            <Col lg="12" md="12">
                              <div
                                className={`check-payment ${
                                  formData.paymentMethods.googlePay ? "selected" : ""
                                }`}
                                style={{
                                  backgroundColor: formData.paymentMethods.googlePay
                                    ? formBackgroundColor
                                    : "",
                                  padding: "20px",
                                }}
                              >
                                <Row>
                                  <Col md="10" lg="10" xs="9">
                                    <label>
                                      <Input
                                        type="checkbox"
                                        className="mt-4"
                                        checked={formData.paymentMethods.googlePay}
                                        onChange={(e) => handleCheckboxChange("paymentMethods", "googlePay", e.target.checked)}
                                      />
                                      Google Pay
                                    </label>
                                  </Col>
                                  <Col md="2" lg="2" xs="3">
                                    <div className="image-section">
                                    <span>
                                        <img src={googlepay} width="40" alt="" />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                {formData.paymentMethods.googlePay && (
                                  <div>
                                    <p>Seamless transaction with Google Pay</p>
                                  </div>
                                )}
                              </div>
                            </Col>

                            {/* Apple Pay */}
                            <Col lg="12" md="12">
                              <div
                                className={`check-payment ${
                                  formData.paymentMethods.applePay ? "selected" : ""
                                }`}
                                style={{
                                  backgroundColor: formData.paymentMethods.applePay
                                    ? formBackgroundColor
                                    : "",
                                  padding: "20px",
                                }}
                              >
                                <Row>
                                  <Col md="10" lg="10" xs="9">
                                    <label>
                                      <Input
                                        type="checkbox"
                                        className="mt-4"
                                        checked={formData.paymentMethods.applePay}
                                        onChange={(e) => handleCheckboxChange("paymentMethods", "applePay", e.target.checked)}
                                      />
                                      Apple Pay
                                    </label>
                                  </Col>
                                  <Col md="2" lg="2" xs="3">
                                    <div className="image-section">
                                    <span>
                                        <img src={apple} alt="" />
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                                {formData.paymentMethods.applePay && (
                                  <div>
                                    <p>Seamless transaction with Apple Pay</p>
                                  </div>
                                )}
                              </div>
                            </Col>

                            <Col lg="12" md="12">
                              <div className="text-lock">
                                <span>
                                  <p>
                                    {" "}
                                    <img src={secure} alt="" /> We protect your
                                    payment information using encryption to
                                    provide bank-level security.
                                  </p>
                                </span>
                              </div>
                            </Col>

                            <Col lg="12" md="12">
                              <div className="refundabletext">
                                <p>
                                  <b>Refundable deposit</b>
                                </p>
                                <p>
                                  An additional €400 security deposit will be
                                  added to your card at the pickup counter and
                                  refunded within a few days of the vehicle's
                                  return.
                                </p>
                                <Row>

                                  <Col lg="6" md="6" xs="6" className="mt-3">
                                    <FormGroup check>
                                      <Input
                                        type="checkbox"
                                        checked={formData.depositOption.depositOption}
                                        onChange={(e) => handleCheckboxChange("depositOption", "depositOption", e.target.checked)}
                                        className='whiteInputCheckbox'
                                        />
                                      <Label check>Pay Now</Label>
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6" md="6" xs="6" className="mt-3">
                                    <FormGroup check>
                                      <Input
                                        type="checkbox"
                                        checked={formData.depositOption.depositCollection}
                                        onChange={(e) => handleCheckboxChange("depositOption", "depositCollection", e.target.checked)}
                                        className='whiteInputCheckbox'

                                     />
                                      <Label check>Pay upon collection</Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>

                              
                            </Col>

                            {/* ... other form fields ... */}

                            {/* Deposit Option */}
                            

                            {/* Agreement */}
                            <Col lg="12" md="12" xs="12" className="mt-3">
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  checked={formData.agreement.agreement}
                                  onChange={(e) => handleCheckboxChange("agreement", "agreement", e.target.checked)}
                                />
                                <Label check>
                                  I have read and accept the rental information,{" "}
                                  <a className='text-decoration-none' href='https://adminqueencar.soundgallery.pk/storage/site/terms-and-condition.pdf' target='_blank'> <span className="bold-color-change">the Terms and Conditions of Rental Agreement,</span></a>{" "}
                                  and the <a href="/ourpolicy" className='text-decoration-none'> <span className="bold-color-change">Privacy and Policy,</span></a>{" "}
                                  and I acknowledge that I'm booking a prepaid rate, where the total
                                  rental price is immediately charged to the credit or debit card I provided.
                                </Label>
                              </FormGroup>
                            </Col>

                            
                            {/* Hidden fields */}
                   
                            {/* Submit Button */}
                            <Col lg="12" className="mt-3 d-none">
                              <Button
                                className="btn-contact-info"
                                color="primary"
                                onClick={handleSubmit}
                              >
                                Save and Continue
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                       

                      </div>
                      
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="5" md="5" xs="12" className="sticky-col" >
              {/* Rewrite it, It is not working properly, 
You didn;t calculate coll7heught i think.|
It is working on scrollup and in negative margin top ,, i dont know you did ref correct, 


*/}

                <div className="rightside-card"  id="rightCard" ref={rightSideCardRef} style={{ marginTop: `${scrollMargin}px` }}>
                  <div className="card">
                    <div className="card-body " >
                      <Row>
                        <Col md="12" lg="12" xs="12">
                          {/* <p className="order-summry">Order Summary</p> */}
                          <h5 className="address-txt" style={{ fontWeight: 600}}>Order Summary</h5>
                          <img src={line} alt="" />
                        </Col>
                      </Row>

                      <Row>
                      
                        <Col md="6" lg="6" xs="6">
                          <div className="righttext-left">
                            <p className="coupon-txt">Coupon Applied</p>
                          </div>
                        </Col>
                        <Col md="6" lg="6" xs="6">
                          <div className="righttext-right">
                            <p className="coupon-price">€{discountPrice}</p>
                          </div>
                        </Col>
                        <Col lg="9" md="9" xs="9">
                          <div className="coupon-field">
                            <div className="form-group">
                              <Input
                                placeholder="Coupon Code"
                                value={formData.couponCode}
                                onChange={(e) => handleInputChange("couponCode", e.target.value)}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col lg="3" md="3" xs="3">
                          <Button
                            className="btn-contact-info mt-1"
                            color="primary"
                            onClick={handleCouponSubmit}
                          >
                            Apply
                          </Button>
                        </Col>
                        

                        </Row>
                         {/* Display coupon message conditionally */}
                         {couponMessage && (
                          <Col lg="12" className={`mt-3 text-${couponMessage.includes('successfully') ? 'success' : 'danger'}`}>
                            {couponMessage}
                          </Col>
                        )}
                     
                        <br />
                        <Row>
                     
                        <hr />
                      <Table borderless>
                        <tbody>
                          <tr>
                            <td className="righttext-left">Pickup Date</td>
                            <td className="righttext-right">{formattedPickupDate}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left">Drop Off Date</td>
                            <td className="righttext-right">{formattedDropoffDate}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left">Fleet Charges</td>
                            <td className="righttext-right">€{fleetPrice}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left">Extra Charges</td>
                            <td className="righttext-right">€{extraPrice}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left">Deposit</td>
                            <td className="righttext-right">€{depositNowAmount}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left">Discount</td>
                            <td className="righttext-right">€{discountPrice}</td>
                          </tr>
                          <tr>
                            <td className="righttext-left"><b><h5>Final Price</h5></b></td>
                            <td className="righttext-right"><b><h5>€{finalPrice}</h5></b></td>
                          </tr>
                        </tbody>
                      </Table>
                      <hr />

                      
                      {/* <Row> */}
                        

                        {/* <Row> */}
                       

                       
                      </Row>

                      {/* <hr /> */}
                      <Row>
                        <Col md="6" lg="6" xs="6">
                          <div className="righttext-left ">
                            <p className="toyota-txt">
                              <b>{checkoutData.product.brand} {checkoutData.product.model}</b>
                            </p>
                            <p className="toyota-txt-p">
                              or similar same group vehicle
                            </p>
                          </div>
                        </Col>
                        <Col md="6" lg="6" xs="6">
                          <div className="righttext-right d-none">
                            <p className="toyota-price">Daily Rate: €{checkoutData.product.daily_rate}</p>
                            <p className="toyota-price">Weekly Rate: €{checkoutData.product.weekly_rate}</p>
                            <p className="toyota-price">Monthly Rate: €{checkoutData.product.monthly_rate}</p>
                            {/* <p className="toyota-txt-small">Total Price</p> */}
                          </div>
                        </Col>
                      </Row>
                     
                      <Row>
                        <Col lg="12" md="12">
                          <div className="lastImage">
                            <img src={checkoutData.product.carImageUrl} alt="" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col lg="12" className="mt-3 d-flex justify-content-center">
                          <Button
                            className="btn-contact-info btn-lg"
                            color="primary"
                            onClick={handleSubmit}
                          >
                            Pay Now
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="ads-section mt-5 d-none">
                    <Row>
                      <Col md="12" lg="12">
                        <div className="ads-text">
                          <h5>Ads</h5>
                        </div>
                        <div className="ads-image">
                          <img src={addImage} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="mt-3 mb-5 airport-section">
          <Container>
            <Row className="align-items-center servc-row1">
              <Col md="6" lg="6" xs="12">
                <div className="servs-img">
                  <img src={groupServices} alt="" style={{ width: "100%" }} />
                </div>
              </Col>
              <Col md="6" lg="6" xs="12">
                <div className="service-about-content">
                  <h2 data-aos="fade-up">
                    <span>Extra</span> Services
                  </h2>
                  <div className="servicesPoint-text">
                    <ul>
                      <li>
                        <p>
                          <img src={ticIcon} alt="" /> Stringent Quality Check
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <img src={ticIcon} alt="" /> Always On-Time
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <img src={ticIcon} alt="" /> Affordable Pricing
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <img src={ticIcon} alt="" /> Punctual & Reliable
                        </p>{" "}
                      </li>
                      <li>
                        <p>
                          <img src={ticIcon} alt="" /> Stringent Quality Check
                        </p>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className='d-none'>
            <Row className="mt-5 mb-5">
              <Col md="6" lg="6">
                <div className="airportPick-section">
                  <div className="airportText">
                    <h3 data-aos="fade-up">Airport pickup</h3>
                    <p>
                      Search Flights & Places Hire to our most popular <br />
                      destinations
                    </p>
                    <div className="extra-btn">
                      <button>
                        <img src={extraArrow} alt="" />
                        EXTRA
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="airportDrop-section">
                  <div className="airportText">
                    <h3 data-aos="fade-up">Airport Drop off</h3>
                    <p>
                      Search hotels & Places Hire to our most popular <br />
                      destinations
                    </p>
                    <div className="extra-btn">
                      <button>
                        <img src={extraArrow} alt="" />
                        EXTRA
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        
        <section className="mb-5">
          <div className="confirm-section">
            <Row>
              <Col md="12" lg="12" xs="12" className="">
                <div className="confirm-text" data-aos="fade-up">
                  <h3>Confirm All Reservation</h3>
                  <h3>Dream Business Today!</h3>
                </div>
           
                <div className="processd-btn">
                  <Button color="primary" onClick={handleSubmit}>Proceed to payment</Button>
                  <span className="arrow-image"></span>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <Footer />
        {loading && (
          <div className="loading-spinner">
             <ClipLoader color="#36D7B7" loading={loading} size={50} />
            Loading...
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout;
