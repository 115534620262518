import React, { Fragment, useEffect, useState  } from "react";
import { Col, Container, Row } from "reactstrap";
import carMain from "../../assets/img/Big_car_main.png";
import callCenter from "../../assets/img/customer-care 1.png";
import traffic from "../../assets/img/traffic-jam 1.png";
import hours from "../../assets/img/24-hours-support.png";

const CarSection = () => {
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])

  return (
    <Fragment>
      <div className="car-section">
        <Container>
          <div className="car-titlebar text-center">
            <h2 className="heading-discover" data-aos="fade-up">
              Our Doors Are Always
              <br />
              <span>Open To Welcome You</span>
            </h2>
            <div
              className="car-img"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <img src={carMain} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className="car-background">
        <Container>
          <Row>
            <Col lg="4" md="4" xs="12">
              <div className="d-flex car-sc">
                <div className="car-count">
                  
                  {/* <h3 className="car-count-heading mb-0">01</h3> */}
                  <div
                    className="car-count-img"
                    data-aos="flip-right"
                    data-aos-duration="1500"
                  >
                    <img src={callCenter} alt="" />
                  </div>
                </div>
                <div className="car-subtext">
                  <h5 data-aos="fade-up" data-aos-duration="1500">
                    {siteSettings?.['benefit_01_heading']}                  
                    </h5>
                  {/* <p>
                    {siteSettings?.['benefit_01_para']}                  
                    </p> */}
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12">
              <div className="d-flex car-sc">
                <div className="car-count">
                  {/* <h3 className="car-count-heading mb-0">02</h3> */}
                  <div
                    className="car-count-img"
                    data-aos="flip-right"
                    data-aos-duration="1500"
                  >
                    <img src={traffic} alt="" />
                  </div>
                </div>
                <div className="car-subtext new-sub-car">
                  <h5 data-aos="fade-up" data-aos-duration="1500">
                    {siteSettings?.['benefit_02_heading']} 
                  </h5>
                  {/* <p>
                    {siteSettings?.['benefit_02_para']} 
                  </p> */}
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12">
              <div className="d-flex car-sc">
                <div className="car-count">
                  {/* <h3 className="car-count-heading mb-0">03</h3> */}
                  <div
                    className="car-count-img"
                    data-aos="flip-right"
                    data-aos-duration="1500"
                  >
                    <img src={hours} alt="" />
                  </div>
                </div>
                <div className="car-subtext">
                  <h5 data-aos="fade-up" data-aos-duration="1500">
                    {siteSettings?.['benefit_03_heading']}                   </h5>
                  {/* <p>
                  {siteSettings?.['benefit_03_para']}  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default CarSection;
