import React, { useState, useEffect } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container,
  UncontrolledAccordion,
} from "reactstrap";
import { getSiteSettings } from "../../Api"; // Import the API function

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    
    if (tempSetting) {
      const parsedSettings = JSON.parse(tempSetting);
      setSiteSettings(parsedSettings);
      setFaqData( JSON.parse(parsedSettings['content_faq']));
    }
  }, []);

  // useEffect(() => {
  //   const tempSetting = localStorage.getItem('site_settings');
  //   setSiteSettings(JSON.parse(tempSetting));
    
  //   if (tempSetting) {
  //     setFaqData(JSON.parse(tempSetting['content_faq']));
  //   }
   
  //   // setFaqData(JSON.parse(siteSettings['content_faq']));
  // }, [])
  
  // useEffect(() => {
  //   // Fetch the content_faq data from the API
  //   getSiteSettings()
  //     .then((response) => {
  //       const contentFaq = response.data.data.find((item) => item.meta === "content_faq");
  //       if (contentFaq) {
  //         setFaqData(JSON.parse(contentFaq.value));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching content_faq data:", error);
  //     });
  // }, []); // Run the effect only once on component mount

  return (
    <div className="faqs-section">
      <Container>
        <div className="faqs-heading">
          <h3>FAQ</h3>
        </div>
        {/* defaultOpen="1" */}
        <UncontrolledAccordion >
          {faqData.map((faq, index) => (
            <AccordionItem key={index}>
              <AccordionHeader targetId={index.toString()}>
                {faq.title}
              </AccordionHeader>
              <AccordionBody accordionId={index.toString()}>
                {faq.description}
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
      </Container>
    </div>
  );
};

export default Faq;
