import React, { Fragment } from "react";
import PageTitle from "../components/PageTitle";
import bgImg from "../assets/img/img-backg-pages.png";
import { Container } from "reactstrap";
import PremiumSection from "../components/Home/PremiumSection";
import Header from "../components/Header";

const TermsCondition = () => {
  return (
    <div className="page-our-policy">
      <Header />
      <PageTitle heading="Terms and Conditions" bgImg={bgImg} gap="228px 0" />
      <div className="section-policy">
        <Container>
          <div className="policy-content">
            <h3>Terms and Conditions</h3>
            <h4>1. Age and Driving License:</h4>
            {/* className="list-number" */}
            <ul >
              <li>
              The minimum age of the driver must be 25 years old.
              </li>
              <li>
                Customers must have a valid for more than 1 year driving license, they are liable for the
                expiry date and recognition of the driving license and must not hold us liable under any
                circumstance.
                </li>
              <li>
                Please note that our staff may request a copy of the passport or ID card and driving license.
              </li>
            </ul>

            <h4>2. Non-Authorised drivers:</h4>
            <ul>
              <li>
                It is the customers' duty to use the vehicle with due diligence, in line with its characteristics,
                    and to obey the road rules. It is also the customers' duty not to allow anyone other than
                    those authorized under this vehicle hire agreement to drive the vehicle. Customers are liable
                    for any damage to the vehicle or to third parties arising from a breach of such terms.
                    </li>
                <li>
                Customers and/or authorized additional drivers are jointly liable for the customers'
                obligations under this Agreement and the relevant laws that are applicable to the same
              </li>
            </ul>

            <h4>3. Condition and Return of Vehicle:</h4>
            <ul>
                <li>
                    Customers must return the hired vehicle in its pre-rental condition, together with all its
                    documents, tyres, tools and accessories, at the place and on the date and time set out in the
                    rental Agreement. Customers must not change the vehicle's technical specifications, keys,
                    equipment, tools and/or accessories, or make changes to its external or interior appearance.
                    </li>
                <li>
                    Otherwise, customers must pay for the expense of returning the vehicle to its pre-rental
                    condition, without detriment to the loss-of income during the period the vehicle is not
                    available for rent because it is being valeted, and for any other damages sustained. Failure
                    to return the vehicle on the date and time set out in the Contract authorizes us to charge
                    customers for each day they use the vehicle after it should have been returned. We will also
                    charge a penalty of €40 for every day of delay to cover the inconvenience caused to us .In
                    the event that the vehicle disappears or is not returned. We reserve the right to bring legal
                    action against the customers before the competent authorities with the full weight and
                    consequences of the law.

                </li>
            </ul>


            <h4>4. Extension of rental period:</h4>
            <ul>
                <li>
                For an extension of the rental period, customers must inform us and sign an extension
                    document. Agreements cannot be extended by phone or by any other means of electronic
                    communication. The return of the vehicle more than 1 hour over the stated end time of the
                    rental contract will generate a charge of € 40.00 per day. If the vehicle receives a fine during
                    the rental period, the driver will be responsible for full payment of the fine. The amount will
                    be deducted from the deposit. We reserve the right to cancel the delivery of the vehicle in
                    case of doubts about the financial capacity of the client or any outstanding debts. Customers
                    receive the rental vehicle in perfect working order, with all its documents, tires, tools and
                    accessories. Before removing the vehicle, the customer is entitled to check the status of the
                    vehicle for themselves.

                </li>
            </ul>

            <h4>5. Responsibility for Damage or Loss:</h4>
            <ul>
                <li>
                The customer is held responsible for all damage to, or loss or theft of, the Vehicle, including
                damage caused by weather, road conditions, and acts of nature. You must report all
                accidents or incidents of theft and vandalism to Us and the police as soon as You discover
                them. In the event of an accident, customers undertake: a) To send us the full details of the
                third party and any witnesses before forty-eight hours have elapsed, as well as a completed
                accident report form stating the number plate, the name and address of the third party, the
                circumstances of the collision, a sketch of the accident, the name of the third party's
                insurance company and, if possible, the number of the insurance policy. The accident report
                form must be signed by the two drivers involved in the accident. If there is no accident report
                form, the diver must call the local authority.

                </li>
            </ul>

            <h4>6. Extra costs and charges:</h4>
            <ul>
                <li>
                Our rate includes: V.A.T. and local taxes. Mileage limited to 90 kilometers per day. There is a
                charge of 0.40€ for each extra kilometer. The cost of adding an Additional driver is €10 per
                day per driver, the cost of hiring a Baby Seat is €8 per day per Baby seat. The extra charge
                is €5 for one-way reservations (where pick up and drop off are at different locations). There
                is an additional charge of € 10.00 for all vehicles collected outside opening hours. To hire a
                GPS device, you must pay a security deposit of 90€. Charge for 'special cleaning' for the
                costs incurred from an additional valeting service arising from the clearly inadequate state of
                the vehicle at the time of its return, with a maximum charge of €150. Up to €280 for the
                expense incurred through the loss of a vehicle's documents or keys, and/or sending a set of
                keys to the office concerned.

                </li>
            </ul>

            <h4>7. Deposit:</h4>
            <ul>
                <li>Deposit cost: €400 (cash or credit card accepted) and is refundable.</li>
            </ul>

            <h4>8. Method of payment:</h4>
            <ul>
                <li>
                As a method of payment we accept payment via debit card, credit card, visa, master card or
                cash. The card used must be in the name of the main driver on the rental agreement.

                </li>
            </ul>

            <h4>9. Modifications:</h4>
            <ul>
                <li>
                    The Agreement may never be for a rental period of more than 28 days. If the customer
                    decides to terminate the Contract in advance, the entirety of the amount paid for the days
                    the vehicle was not used (including taxes) will be retained as an indemnity. Likewise, in the
                    event that it is us who decides to finalize the rental before the date indicated in the Contract,
                    the customer shall have the right to receive the amount that was paid for the days the
                    vehicle was not used (including taxes) as an indemnity. The deposit given as surety may not
                    be used to extend the rental period under any circumstance. We may charge customers a
                    fee for any extensions to this Agreement. In the event that the Agreement cannot be
                    extended because no vehicles are available or for any other reason, customers must return
                    the vehicle on the agreed date and time. Depending on the terms and conditions in the initial
                    Contract, a new contract may need to be signed when an extension of the rental period is
                    requested. In that case, the new contract invalidates the current Contract.

                </li>
            </ul>

            <h4>10. Data Protection:</h4>
            <ul>
                <li>
                    We hereby assure you that your personal data will be secured and only used for the purpose
                    of our agreement. This will be added to a computer file kept by this company.

                </li>
            </ul>

            <h4>11. Breach of Agreement:</h4>
            <ul>
                <li>
                    If the customer breaches the contract, legal action will be taken.
                </li>
            </ul>

            <h4>Declaration</h4>
            <ul className="pb-5">
                <li>
                    I hereby declare that the best of my knowledge and belief that the particulars given herein
                    are true and no information has been withheld which might influence acceptance which with
                    this declaration shall form the basis of the agreement.

                </li>
            </ul>
            
          </div>
        </Container>
      </div>
      <PremiumSection />
    </div>
  );
};

export default TermsCondition;
