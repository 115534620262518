import React ,  { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import groupServices from "../../assets/img/group-service.png";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const CityServiceSection = () => {

  const navigate = useNavigate();
  const [siteSettings, setSiteSettings] = useState(null);

  useEffect(() => {
    const tempSetting = localStorage.getItem('site_settings');
    setSiteSettings(JSON.parse(tempSetting));
  }, [])
  

  const handleChangeFleet = () => {
    // Navigate to the "/FleetView" route
    navigate('/FleetView');
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };

  return (
    <div className="services-section">
      <Container>
        <div className="services-get">
          <Row className="align-items-center">
            <Col md="6" lg="6" xs="12">
              <div className="servs-img">
                <img src={groupServices} alt="" style={{ width: "100%" }} />
              </div>
            </Col>
            <Col md="6" lg="6" xs="12">
              <div className="service-content">
                {/* <h5 className="get-text-color">Get to know us</h5> */}
                <h3 className="mb-3">Services with a Wide Range of Cars</h3>
                {/* <h5 className="color-change-first">
                  {siteSettings?.['gettoknow_heading']}
                </h5> */}
                {/* <h5 className="color-change-second">exceptional service.</h5> */}
                <p className="mb-3">
                {siteSettings?.['gettoknow_para']}
                </p>
                <ul className="service-menu">
                  <li>{siteSettings?.['gettoknow_point1']}</li>
                  <li>{siteSettings?.['gettoknow_point2']}</li>
                  <li>{siteSettings?.['gettoknow_point3']}</li>
                  <li>{siteSettings?.['gettoknow_point4']}</li>

         
                </ul>

                <Button color="primary" className="mt-1" onClick={handleChangeFleet}>
                  Choose Car
                </Button>
                
                
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CityServiceSection;
